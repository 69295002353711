import get from 'lodash/get'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { createUseStyles } from 'react-jss'
import addEventListener from 'dom-helpers/addEventListener'
import round from '../helpers/round'
import defaultImage from '../images/spacer'

const Picture = ({ classes, alt, sizes }) => {
  const hasWebp = !!get(sizes, [0, 'webpUrl'])
  const srcset = key => sizes.map(item => (`${item[key]} ${item.width}w`)).join()
  return (
    <picture>
      {hasWebp && <source data-srcset={srcset('webpUrl')} type='image/webp' />}
      {sizes && <source data-srcset={srcset('url')} />}
      <img
        data-sizes='auto'
        alt={alt}
        className={cn(sizes && 'lazyload', classes.image)}
        src={defaultImage}
      />
    </picture>
  )
}

const useFadeOnLoadedStyle = (loaded) => useMemo(() => ({
  opacity: loaded ? 0 : 1
}), [loaded])

const LqipPlaceholder = React.forwardRef(({ classes, alt, blur, loaded }, ref) => {
  const style = useFadeOnLoadedStyle(loaded)
  return (
    <img
      ref={ref}
      key='blur'
      src={blur.data || blur.url}
      className={classes.lqipPlaceholder}
      alt={alt}
      style={style}
    />
  )
})

const ColorPlaceholder = React.forwardRef(({ classes, color, loaded }, ref) => {
  const loadedStyle = useFadeOnLoadedStyle(loaded)
  const style = useMemo(() => ({
    ...loadedStyle,
    backgroundColor: color
  }), [color, loadedStyle])
  return <div className={classes.colorPlaceholder} style={style} ref={ref} />
})

const Placeholder = ({ palette, blur, sizes, ...rest }) => {
  const color = get(palette, ['muted', 'background'], get(palette, ['dominant', 'background']))
  const [loaded, setLoaded] = useState(false)
  const hideOnLazyLoaded = useCallback((img) => {
    if (img) {
      addEventListener(img.parentNode, 'lazyloaded', () => {
        setLoaded(true)
      })
    }
  }, [setLoaded])
  useEffect(() => {
    // If the image changes, we want to put the placeholder back until the new image loads in.
    setLoaded(false)
  }, [get(sizes, [0, 'url'])])
  const props = {
    ...rest,
    loaded,
    ref: hideOnLazyLoaded
  }
  if (color) {
    return <ColorPlaceholder {...props} color={color} />
  } else if (blur) {
    return <LqipPlaceholder {...props} blur={blur} />
  }
  return null
}

const NoScript = ({ classes, alt, sizes }) => {
  if (sizes) {
    return (
      <noscript>
        <img
          src={sizes[sizes.length - 1].url}
          srcSet={sizes.map(item => (`${item.url} ${item.width}w`)).join()}
          className={classes.image}
          alt={alt}
        />
      </noscript>
    )
  }
  return null
}

const ResponsiveImage = ({ aspect, children, className, alt, sizes, palette, blur }) => {
  const classes = useStyles({ aspect })
  return (
    <div className={cn(classes.container, className)}>
      <Picture classes={classes} alt={alt} sizes={sizes} />
      <Placeholder classes={classes} alt={alt} sizes={sizes} palette={palette} blur={blur} />
      <NoScript classes={classes} alt={alt} sizes={sizes} />
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  stretch: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  container: {
    position: 'relative',
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    // On Safari, if this container is not the same size and shape as the placeholder
    // (e.g. this container has been made circular), then placeholder escapes the bounds
    // of the container while it transitions. This willChange fixes that.
    willChange: 'opacity',
    '&::before': {
      display: 'block',
      content: '""',
      paddingTop: ({ aspect }) => aspect ? `${round(100 / aspect)}%` : undefined
    }
  },
  image: {
    extend: 'stretch',
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"' // object-fit polyfill
  },
  placeholder: {
    extend: 'stretch',
    transition: 'opacity 400ms'
  },
  lqipPlaceholder: {
    extend: 'placeholder',
    objectFit: 'cover',
    fontFamily: '"object-fit: cover;"', // object-fit polyfill
    imageRendering: 'pixelated',
    fallbacks: {
      imageRendering: 'optimizeSpeed'
    }
  },
  colorPlaceholder: {
    extend: 'placeholder'
  },
  link: {
    textDecoration: 'none'
  }
}, { name: 'ResponsiveImage' })

export default ResponsiveImage
