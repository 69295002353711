import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { srOnly } from '../styles/theme'

const SectionTitle = ({ children, className, srOnly = true }) => {
  const classes = useStyles()
  return (
    <h2 className={cn(srOnly && classes.srOnly, className)}>{children}</h2>
  )
}

const useStyles = createUseStyles({
  srOnly
}, { name: 'SectionTitle' })

export default SectionTitle
