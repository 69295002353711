import inDOM from 'dom-helpers/canUseDOM'
import React, { useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import { supportedProperty } from 'css-vendor'
import { useDispatch, useSelector } from 'react-redux'
import color from 'color'
import gsap from 'gsap'
import theme, { expandAbsolutely, srOnly } from '../styles/theme'
import { modalDismissed } from '../actions'
import { getModalProperties, getModalType, isModalOpen } from '../selectors'
import Archived from './Archived'
import { ReactComponent as CloseIcon } from './close.svg'
import { addBackgroundColorListener, removeBackgroundColorListener } from '../middlewares/view/colors'

const useCurtainColorScheme = () => {
  const listenerRef = useRef()
  return useCallback((curtain) => {
    if (curtain) {
      const modal = curtain.childNodes[0]
      listenerRef.current = backgroundColor => {
        const curtainBackgroundColor = color(backgroundColor).alpha(curtainOpacity).rgb().toString()
        gsap.set(curtain, { backgroundColor: curtainBackgroundColor })
        gsap.set(modal, { backgroundColor })
      }
      addBackgroundColorListener(listenerRef.current)
    } else if (listenerRef.current) {
      removeBackgroundColorListener(listenerRef.current)
      listenerRef.current = null
    }
  }, [])
}

const ModalContent = () => {
  const type = useSelector(getModalType)
  const modalProperties = useSelector(getModalProperties)
  switch (type) {
    case 'archived':
      return <Archived {...modalProperties} />
    default:
      return null
  }
}

const Modal = () => {
  if (inDOM) {
    const dispatch = useDispatch()
    const classes = useStyles()
    const isOpen = useSelector(isModalOpen)
    const onCloseClick = useCallback(() => dispatch(modalDismissed()), [])
    const onClick = useCallback((e) => {
      if (e.target.tagName === 'A' || e.target === e.currentTarget) {
        onCloseClick()
      }
    }, [])
    const curtainRef = useCurtainColorScheme()

    if (isOpen) {
      return ReactDOM.createPortal(
        <div className={classes.curtain} onClick={onClick} ref={curtainRef}>
          <div className={classes.modal}>
            <button className={classes.close} type='button' onClick={onCloseClick}>
              <span className={classes.srOnly}>Close</span>
              <CloseIcon className={classes.closeIcon} />
            </button>
            <ModalContent />
          </div>
        </div>,
        document.body
      )
    }
  }
  return null
}

const hasBackdropFilter = supportedProperty('backdrop-filter')
const curtainOpacity = hasBackdropFilter ? 0.8 : 0.9

const useStyles = createUseStyles({
  srOnly,
  curtain: {
    ...expandAbsolutely,
    position: 'fixed',
    background: color(theme.colors.primary).alpha(curtainOpacity).rgb().toString(),
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.modal,
    display: 'flex'
  },
  modal: {
    margin: 'auto',
    position: 'relative',
    width: '50%',
    minWidth: 250,
    background: theme.colors.primary,
    padding: 25,
    border: '1px solid',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
      '& h3': {
        fontSize: 28
      }
    },
    [theme.breakpoints.up('md')]: {
      padding: 40
    }
  },
  close: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: 0,
    display: 'block',
    outline: 0,
    color: 'inherit',
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 10,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      padding: 20
    }
  },
  closeIcon: {
    display: 'block',
    width: 20,
    height: 20,
    [theme.breakpoints.up('md')]: {
      width: 40,
      height: 40
    }
  }
}, { name: 'Modal' })

export default Modal
