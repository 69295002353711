import React, { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import get from 'lodash/get'
import map from 'lodash/map'
import range from 'lodash/range'
import fromPairs from 'lodash/fromPairs'
import { getPortfolioItems, getPortfolioTitle } from '../../selectors'
import SectionTitle from '../SectionTitle'
import Item from './Item'
import theme from '../../styles/theme'
import Work from './Work'
import { portfolioMountedCreator, portfolioUnmountedCreator } from '../../actions'
import useMountUnmountActions from '../hooks/useMountUnmountActions'

const itemChildNames = [
  'container',
  'title',
  'titleEtch',
  'titleType',
  'titleTypeEtch',
  'awards'
]

function useItemRefs (length) {
  const ref = useRef([])
  if (ref.current.length !== length) {
    ref.current = map(range(length), i => ref.current[i] ||
      fromPairs(map(itemChildNames, childName => [
        `${childName}Ref`,
        ({ current: null })
      ]))
    )
  }
  return ref.current
}

const Portfolio = ({ className, selectedIndex = -1 }) => {
  const classes = useStyles()
  const items = useSelector(getPortfolioItems)
  const portfolioTitle = useSelector(getPortfolioTitle)
  const selectedWorkTitle = get(items, [selectedIndex, 'title'])
  const title = selectedWorkTitle || portfolioTitle
  const itemRefs = useItemRefs(items.length)
  useMountUnmountActions(itemRefs, portfolioMountedCreator, portfolioUnmountedCreator)
  const listView = selectedIndex < 0
  return (
    <article className={cn(className, classes.container)}>
      <SectionTitle>{title}</SectionTitle>
      <div className={cn(classes.items, !listView && classes.workViewItems)}>
        {items.map((item, i) => (
          <Item
            key={i}
            className={cn(
              classes.item,
              i === 0 && classes.firstItem,
              i === items.length - 1 && classes.lastItem
            )}
            index={i}
            alignment={i % 2 === 0 ? 'left' : 'right'}
            listView={listView}
            selected={selectedIndex === i}
            refs={itemRefs[i]}
          />
        ))}
      </div>
      <Work
        className={cn(classes.work, listView && classes.listViewWork)}
        index={selectedIndex}
      />
    </article>
  )
}

const marginToCentre = (prop, at = '50vh') => {
  const aspect = 4 / 3
  const factor = 1 / (aspect)
  return {
    [prop]: `calc(${at} - (${50 * factor}vw + ${theme.padding.body.top - theme.padding.body.left * factor}px))`,
    [theme.breakpoints.up('md')]: {
      [prop]: `calc(${at} - (${50 * factor}vw + ${theme.padding.md.body.top - theme.padding.md.body.left * factor}px))`
    },
    [theme.breakpoints.up('xxl')]: {
      [prop]: `calc(${at} - ${(theme.containerMaxWidth / aspect) / 2 + theme.padding.md.body.top}px)`
    },
    '.no-js &': {
      [prop]: 0
    }
  }
}

const useStyles = createUseStyles({
  workViewItems: {
    '.no-js &': {
      display: 'none'
    }
  },
  listViewWork: {
    '.no-js &': {
      display: 'none'
    }
  },
  item: {
    [theme.breakpoints.down('sm')]: {
      margin: ['10vh', 0]
    }
  },
  firstItem: {
    marginTop: `calc(50vh - ${theme.padding.body.top}px)`,
    [theme.breakpoints.up('md')]: {
      marginTop: `calc(50vh - ${theme.padding.md.body.top}px)`
    }
  },
  lastItem: {
    ...marginToCentre('marginBottom')
  }
}, { name: 'Portfolio' })

export default Portfolio
