import { useCallback } from 'react'
import gsap from 'gsap'
import PhysicsPropsPlugin from 'gsap/PhysicsPropsPlugin'
import VelocityTracker from 'gsap/utils/VelocityTracker'
import { getPageYOffset } from '../../../middlewares/view/scroll'

gsap.registerPlugin(VelocityTracker)
gsap.registerPlugin(PhysicsPropsPlugin)

const duration = 1.5
const maxVelocity = 120
const degreesPerPixel = 0.03

const scrolled = {
  get y () {
    return getPageYOffset()
  }
}

const getDirection = value => Math.sign(value) || 1

export function setLogoVelocity (el, pixelsPerSecond) {
  const prevVelocity = el.prevVelocity || 0
  const direction = getDirection(pixelsPerSecond)
  const velocity = Math.min(Math.abs(pixelsPerSecond * degreesPerPixel), maxVelocity) * direction
  // Only adjust the logo velocity if the scroll velocity increases or changes direction; otherwise just let it
  // decelerate naturally
  if (velocity && (
    Math.abs(velocity) > Math.abs(prevVelocity) ||
    direction !== getDirection(prevVelocity)
  )) {
    el.prevVelocity = velocity
    gsap.to(el, {
      duration,
      physicsProps: {
        rotation: {
          velocity,
          acceleration: -velocity / duration
        }
      },
      onComplete: () => {
        el.prevVelocity = 0
      }
    })
  }
}

export default function useScrollingLogo () {
  let onTick
  return useCallback((el) => {
    if (el) {
      VelocityTracker.track(scrolled, 'y')
      onTick = () => setLogoVelocity(el.childNodes[0], VelocityTracker.getVelocity(scrolled, 'y'))
      gsap.ticker.add(onTick)
    } else {
      gsap.ticker.remove(onTick)
      VelocityTracker.untrack(scrolled, 'y')
    }
  }, [])
}
