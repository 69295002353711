import inDOM from 'dom-helpers/canUseDOM'
import map from 'lodash/map'
import without from 'lodash/without'
import sample from 'lodash/sample'
import pull from 'lodash/pull'
import each from 'lodash/each'
import keys from 'lodash/keys'
import gsap, { Expo } from 'gsap'
import color from 'color'
import {
  awaitNextHeroItemIndexUpdate,
  cancelAwaitNextHeroItemIndexUpdate,
  getHeroItemIndex
} from './portfolioScroll'
import theme from '../../styles/theme'
import {
  getCurrentLocationHash,
  getCurrentPortfolioItemIndex,
  isMenuOpen,
  isPortfolioRoute,
  isWorkRoute
} from '../../selectors'
import supportsCssVariables from '../../helpers/supportsCssVariables'
import {
  isRouteActionType,
  LOCATION_HASH_CHANGED,
  REHYDRATED,
  ROUTE_EXIT_TRANSITION_ENDED,
  TOGGLE_MENU
} from '../../actions'

const schemes = theme.colors.schemes
let ticking = false
let currentNearestSchemeIndex = 0

const schemeTarget = (() => {
  const values = { ...theme.colors.schemes[0] }
  const listeners = {}

  const object = {
    addListener: (propertyName, listener, { callImmediately = true } = {}) => {
      listeners[propertyName].push(listener)
      if (callImmediately) {
        // initialize with the current value
        listener(values[propertyName])
      }
    },
    removeListener: (propertyName, listener) => {
      pull(listeners[propertyName], listener)
    }
  }

  each(keys(values), propertyName => {
    listeners[propertyName] = []
    Object.defineProperty(object, propertyName, {
      get () {
        return values[propertyName]
      },
      set (value) {
        if (value !== values[propertyName]) {
          values[propertyName] = value
          each(listeners[propertyName], listener => {
            listener(value)
          })
        }
      }
    })
  })

  return object
})()

export const addBackgroundColorListener = schemeTarget.addListener.bind(schemeTarget, 'backgroundColor')
export const removeBackgroundColorListener = schemeTarget.removeListener.bind(schemeTarget, 'backgroundColor')
export const addColorListener = schemeTarget.addListener.bind(schemeTarget, 'color')
export const removeColorListener = schemeTarget.removeListener.bind(schemeTarget, 'color')
export const getCurrentBackgroundColor = () => schemeTarget.backgroundColor
export const getCurrentColor = () => schemeTarget.color
export const getCurrentNearestBackgroundColor = () => schemes[currentNearestSchemeIndex].backgroundColor

const isTrackingScroll = isPortfolioRoute
const ease = Expo.easeInOut

export default store => {
  if (!(inDOM && supportsCssVariables)) {
    // During SSR, do nothing
    return next => action => next(action)
  }

  const colorListener = color => {
    gsap.set(document.body, { color })
  }
  const backgroundColorListener = backgroundColor => {
    gsap.set(document.body, { backgroundColor })
  }

  addColorListener(colorListener)
  addBackgroundColorListener(backgroundColorListener)

  const calculateCurrentSchemeMix = () => {
    const heroItemIndex = getHeroItemIndex() + 0.1
    const positiveIndex = Math.max(0, heroItemIndex)
    const closestIndex = Math.round(positiveIndex)
    let prevIndex
    let nextIndex
    if (closestIndex > positiveIndex) {
      // we are still transitioning from closestIndex-1 to closestIndex
      prevIndex = Math.max(0, closestIndex - 1)
      nextIndex = closestIndex
    } else if (positiveIndex > 0) {
      // we have now passed closestIndex
      prevIndex = closestIndex
      nextIndex = closestIndex + 1
    } else {
      // haven't scrolled up to the first item yet
      prevIndex = closestIndex
      nextIndex = 0
    }

    const schemeAIndex = prevIndex % schemes.length
    const schemeBIndex = nextIndex % schemes.length
    const schemeA = schemes[schemeAIndex]
    const schemeB = schemes[schemeBIndex]
    if (schemeA === schemeB) {
      currentNearestSchemeIndex = schemeAIndex
      return schemeA
    } else {
      // Get the fractional part of heroItemIndex
      const t = positiveIndex - Math.floor(positiveIndex)
      currentNearestSchemeIndex = t < 0.5 ? schemeAIndex : schemeBIndex
      const weight = ease(t)
      return {
        backgroundColor: color(schemeA.backgroundColor).mix(color(schemeB.backgroundColor), weight).hex(),
        color: weight >= 0.5 ? schemeB.color : schemeA.color
        // Note: Removed text color tween because the Recalculate Styles is too expensive. It’s taking
        // about 9ms on my machine at the moment which is far longer than I can afford. Flicking instantly
        // from black to white means we’ll only drop a single frame during scrolling instead of stuttering.
        // color: color(schemeA.color).mix(color(schemeB.color), ease(weight)).hex()
      }
    }
  }

  const onTick = () => {
    const scheme = calculateCurrentSchemeMix()
    gsap.set(schemeTarget, scheme)
  }

  const transitionToScheme = (scheme, extraVars) => {
    return gsap.to(schemeTarget, {
      ...scheme,
      overwrite: 'all',
      duration: 0.5,
      ...extraVars
    })
  }

  const onNextHeroItemIndexUpdate = () => {
    const scheme = calculateCurrentSchemeMix()
    transitionToScheme(scheme, {
      onComplete: () => {
        gsap.ticker.add(onTick)
      }
    })
  }

  const trackScroll = (immediate = false) => {
    if (!ticking) {
      ticking = true
      if (immediate) {
        gsap.ticker.add(onTick)
      } else {
        // Need to defer until the heroItemIndex has been updated for the new scroll position.
        awaitNextHeroItemIndexUpdate(onNextHeroItemIndexUpdate)
      }
    }
  }

  const disableTrackScroll = () => {
    if (ticking) {
      gsap.killTweensOf(schemeTarget)
      cancelAwaitNextHeroItemIndexUpdate(onNextHeroItemIndexUpdate)
      gsap.ticker.remove(onTick)
      ticking = false
    }
  }

  return next => action => {
    const prevHash = getCurrentLocationHash(store.getState())
    const ret = next(action)
    const navigating = isRouteActionType(action.type)
    let rehydrated = action.type === REHYDRATED

    if (module.hot && process.env.NODE_ENV === 'development') {
      if (action.type === 'HMR_RELOADED') {
        rehydrated = true
      }
      if (action.type === 'HMR_DISPOSED') {
        disableTrackScroll()
        removeColorListener(colorListener)
        removeBackgroundColorListener(backgroundColorListener)
      }
    }

    if (!(
      navigating ||
      rehydrated ||
      action.type === TOGGLE_MENU ||
      action.type === ROUTE_EXIT_TRANSITION_ENDED ||
      action.type === LOCATION_HASH_CHANGED
    )) {
      if (module.hot && process.env.NODE_ENV === 'development') {
        if (!(action.type === 'HMR_RELOADED' || action.type === 'HMR_DISPOSED')) {
          return ret
        }
      } else {
        return ret
      }
    }

    const state = store.getState()
    const tracking = isTrackingScroll(state)
    const menuOpen = isMenuOpen(state)
    const enabled = tracking && !menuOpen

    if (rehydrated) {
      if (enabled) {
        trackScroll(true)
      } else if (isWorkRoute(state)) {
        currentNearestSchemeIndex = getCurrentPortfolioItemIndex(state) % schemes.length
        const scheme = schemes[currentNearestSchemeIndex]
        gsap.set(schemeTarget, scheme)
      }
    }

    if (!enabled && navigating) {
      disableTrackScroll()
    }

    if (tracking && action.type === TOGGLE_MENU) {
      if (menuOpen) {
        // Transition to nearest scheme to ensure the menu is readable
        const scheme = schemes[currentNearestSchemeIndex]
        disableTrackScroll(true)
        transitionToScheme(scheme)
      } else {
        trackScroll()
      }
    }

    const hash = getCurrentLocationHash(store.getState())
    const validHashChange = action.type === LOCATION_HASH_CHANGED && prevHash && hash
    if (action.type === ROUTE_EXIT_TRANSITION_ENDED || validHashChange) {
      if (enabled) {
        trackScroll()
      } else if (isWorkRoute(state)) {
        currentNearestSchemeIndex = getCurrentPortfolioItemIndex(state) % schemes.length
        const scheme = schemes[currentNearestSchemeIndex]
        transitionToScheme(scheme)
      } else {
        // transition to a random other scheme
        const otherIndexes = without(map(schemes, (scheme, index) => index), currentNearestSchemeIndex)
        const nextSchemeIndex = sample(otherIndexes)
        let pastHalfway = false
        transitionToScheme(schemes[nextSchemeIndex], {
          onUpdate: function () {
            if (!pastHalfway && this.progress() >= 0.5) {
              pastHalfway = true
              currentNearestSchemeIndex = nextSchemeIndex
            }
          }
        })
      }
    }

    return ret
  }
}
