import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from '../Link'
import mergeClassNames from '../../helpers/mergeClassNames'
import theme, { srOnly } from '../../styles/theme'

const NavLink = ({ classes: customClasses, active, seoTitle, children, ...rest }) => {
  const classes = mergeClassNames(useStyles(), customClasses)
  const title = children
  const [titleClassName, seoTitleClassName] = (
    (title && seoTitle && title.length < seoTitle.length)
      ? [classes.shorterTitle, classes.longerTitle]
      : [classes.longerTitle, classes.shorterTitle]
  )
  return (
    <Link className={cn(classes.link, active && classes.activeLink)} {...rest}>
      {seoTitle ? (
        <>
          <span className={titleClassName}>{title}</span>
          <span className={seoTitleClassName}>
            <span className={classes.srOnly}> (</span>
            {seoTitle}
            <span className={classes.srOnly}>)</span>
          </span>
        </>
      ) : title}
    </Link>
  )
}

const useStyles = createUseStyles({
  srOnly,
  link: {
    display: 'inline-block',
    '&:not($activeLink)': {
      boxShadow: 'none'
    }
  },
  longerTitle: {
    [theme.breakpoints.only('md')]: {
      ...srOnly
    }
  },
  shorterTitle: {
    [theme.breakpoints.down('sm')]: {
      ...srOnly
    },
    [theme.breakpoints.up('lg')]: {
      ...srOnly
    }
  },
  activeLink: {}
}, { name: 'NavLink' })

export default NavLink
