import React, { useEffect, useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { isElement, isFragment } from 'react-is'
import cn from 'classnames'
import repeat from 'lodash/repeat'
import theme from '../styles/theme'
import Link from './Link'
import { addBackgroundColorListener, removeBackgroundColorListener } from '../middlewares/view/colors'

const DefinitionContent = React.forwardRef(({ className, children, transparent }, ref) => {
  if (transparent) {
    return children
  }
  // in case the child renders as an <a> (which uses boxShadow too, so is incompatible with the Definition css), it's
  // safer to wrap in a <span> here instead of using cloneElement
  return <span className={className} ref={ref}>{children}</span>
})

const useColorScheme = () => {
  const termTextRef = useRef()
  const descriptionTextRef = useRef()
  useEffect(() => {
    const applyColorScheme = (element, { backgroundColor, boxShadow }) => {
      element.style.setProperty('background-color', backgroundColor)
      element.style.setProperty('box-shadow', boxShadow)
    }
    const listener = nextBackgroundColor => {
      if (termTextRef.current) {
        applyColorScheme(termTextRef.current, termTextColorScheme(nextBackgroundColor))
      }
      if (descriptionTextRef.current) {
        applyColorScheme(descriptionTextRef.current, descriptionTextColorScheme(nextBackgroundColor))
      }
    }
    addBackgroundColorListener(listener)
    return () => {
      removeBackgroundColorListener(listener)
    }
  }, [])
  return [termTextRef, descriptionTextRef]
}

export const Definition = ({ term, description, url, transparent }) => {
  const classes = useDefinitionStyles({ transparent })
  const link = useMemo(() => url ? {
    url,
    text: term,
    target: '_blank'
  } : null, [term, url])
  const [termTextRef, descriptionTextRef] = useColorScheme()
  return (
    <div className={classes.definition}>
      <dt className={classes.term}>
        <DefinitionContent className={classes.termText} transparent={transparent} ref={termTextRef}>
          {link ? <Link link={link} /> : term}
        </DefinitionContent>
      </dt>
      <template className={classes.leader} />
      <dd className={classes.description}>
        <DefinitionContent className={classes.descriptionText} transparent={transparent} ref={descriptionTextRef}>
          {description}
        </DefinitionContent>
      </dd>
    </div>
  )
}

const maybeTransparentChildren = (children, transparent) => (
  React.Children.map(children, child => {
    if (!isElement(child)) {
      return child
    }
    const props = {}
    if (!isFragment(child)) {
      props.transparent = transparent
    }
    return React.cloneElement(child, props, maybeTransparentChildren(child.props.children, transparent))
  })
)

const Definitions = ({ className, children, definitions, transparent = false }) => {
  const classes = useDefinitionsStyles()
  return (
    <dl className={cn(className, classes.definitions)}>
      {definitions
        ? definitions.map(({ title, detail, url }, i) => (
          <Definition key={i} term={title} description={detail} url={url} transparent={transparent} />
        ))
        : maybeTransparentChildren(children, transparent)}
    </dl>
  )
}

const useDefinitionsStyles = createUseStyles({
  definitions: {
    marginTop: 0,
    marginBottom: 50
  }
}, { name: 'Definitions' })

const termTextColorScheme = (backgroundColor) => ({
  // background color to clip off the dots
  backgroundColor,
  // plus box shadow to extend the background color on the rhs
  boxShadow: `10px 0 0 ${backgroundColor}`
})

const descriptionTextColorScheme = (backgroundColor) => ({
  backgroundColor,
  boxShadow: `-10px 0 0 ${backgroundColor}`
})

const useDefinitionStyles = createUseStyles({
  definition: {
    marginBottom: '0.5em',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: 1 // makes sure underlines don't get clipped off on iOS Safari
  },
  term: {
    fontWeight: 'bold',
    margin: [0, 8, 0, 0],
    position: 'relative'
  },
  description: {
    margin: [0, 0, 0, 8],
    textAlign: 'right',
    position: 'relative'
  },
  termText: {
    ...termTextColorScheme(theme.colors.primary)
  },
  descriptionText: {
    ...descriptionTextColorScheme(theme.colors.primary)
  },
  leader: {
    display: 'block',
    overflow: ({ transparent }) => transparent ? 'hidden' : null,
    flex: '1 0 2em',
    height: '1.4em',
    position: ({ transparent }) => transparent ? 'relative' : null,
    zIndex: ({ transparent }) => transparent ? null : -1,
    '&::before': {
      content: `"${repeat('.', 80)}"`,
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      fontWeight: 'normal'
    }
  }
}, { name: 'Definition' })

export default Definitions
