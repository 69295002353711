import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import qsa from 'dom-helpers/querySelectorAll'
import gsap from 'gsap'
import slice from 'lodash/slice'
import color from 'color'
import { isMenuOpen } from '../../../selectors'
import { getCurrentBackgroundColor, getCurrentNearestBackgroundColor } from '../../../middlewares/view/colors'

const duration = 0.4

const backgroundColorProps = (backgroundColor, opacity) => ({
  backgroundColor: color(backgroundColor).alpha(opacity).rgb().toString()
})

export default function useMenuFromHamburgerTransition () {
  const hamburgerRef = useRef()
  const menuRef = useRef()
  const open = useSelector(isMenuOpen)
  const locals = useRef({ open }).current

  const killTimeline = () => {
    if (locals.timeline) {
      locals.timeline.kill()
      delete locals.timeline
    }
  }

  if (module.hot) {
    // If running under HMR, we might start in the open state so this just makes everything consistent
    useEffect(() => {
      if (open) {
        gsap.set(menuRef.current, { opacity: 1, ...backgroundColorProps(getCurrentBackgroundColor(), 1) })
        gsap.set(qsa(hamburgerRef.current, ':scope > *:not(:nth-child(n + 4))'), { opacity: 0 })
      }
    }, [])
  }

  useEffect(() => {
    killTimeline()

    if (open === locals.open) {
      // not transitioning, so do nothing
      return
    }
    locals.open = open

    if (!hamburgerRef.current || !menuRef.current) {
      // nothing to animate, so do nothing
      return
    }

    const timeline = locals.timeline = gsap.timeline()
      .eventCallback('onComplete', killTimeline)
      .fromTo(
        menuRef.current,
        {
          ...backgroundColorProps(getCurrentBackgroundColor(), 0)
        }, {
          duration,
          ...backgroundColorProps(getCurrentNearestBackgroundColor(), 1)
        })

    // find the hamburger and the menu items
    const menuItems = qsa(menuRef.current, 'a')
    const strokes = slice(hamburgerRef.current.childNodes, 0, menuItems.length)
    const dividers = qsa(menuRef.current, 'a + span')

    // remove existing css so we can measure their natural positions
    gsap.set([menuItems, strokes], { clearProps: 'all' })

    for (let i = 0; i < strokes.length; ++i) {
      const stroke = strokes[i]
      const item = menuItems[i]
      const strokeRect = stroke.getBoundingClientRect()
      const itemRect = item.getBoundingClientRect()
      // transform-origin is the centre of the items, so need to translate based on centres!
      const strokeX = (strokeRect.left + strokeRect.right) / 2
      const strokeY = (strokeRect.top + strokeRect.bottom) / 2
      const itemX = (itemRect.left + itemRect.right) / 2
      const itemY = (itemRect.top + itemRect.bottom) / 2
      const x = strokeX - itemX
      const y = strokeY - itemY
      const scaleX = strokeRect.width / itemRect.width
      // for scaleY, take the text line-height of 1.4 into account
      const scaleY = strokeRect.height / (itemRect.height / 1.4)
      const itemTimeline = gsap.timeline()
        .set(stroke, { opacity: 0 })
        .from(item, {
          duration,
          x,
          y,
          scaleX,
          scaleY,
          clearProps: 'all',
          ease: 'power3.in'
        })
        .eventCallback('onReverseComplete', () => {
          // finished transitioning this item, so now allow this stroke to be visible again
          gsap.set([
            stroke
          ], {
            clearProps: 'all'
          })
        })

      // stagger this item into the main timeline
      timeline.add(itemTimeline, i * 0.08)
    }

    // after everything else, fade in the dividers
    timeline.fromTo(dividers, { duration: 0.1, opacity: 0 }, { opacity: 1 })

    timeline.eventCallback('onReverseComplete', () => {
      // finished closing the menu, now remove all styles that we set manually
      gsap.set([
        menuItems,
        dividers,
        strokes,
        menuRef.current
      ], {
        clearProps: 'all'
      })
      killTimeline()
    })

    if (!open) {
      timeline.reverse(0)
      // when closing, hide the dividers instantly
      gsap.set(dividers, { opacity: 0 })
    }

    // Finally, the menu container needs to be visible for the transition to be visible
    gsap.set(menuRef.current, { opacity: 1 })
  }, [open, hamburgerRef, menuRef, locals])

  return { hamburgerRef, menuRef }
}
