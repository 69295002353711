export const SHOW_ARCHIVED_LINK_MODAL = 'SHOW_ARCHIVED_LINK_MODAL'
export const MODAL_DISMISSED = 'MODAL_DISMISSED'

export const showArchivedLinkModal = (url) => ({
  type: SHOW_ARCHIVED_LINK_MODAL,
  payload: { url }
})

export const modalDismissed = () => ({
  type: MODAL_DISMISSED
})
