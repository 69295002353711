import inDOM from 'dom-helpers/canUseDOM'
import {
  hasScrollHintDelayElapsed,
  isScrolledDown,
  isScrollHintRequired,
  isScrollHintScrolledDown,
  isScrollHintSeen
} from '../../selectors'
import {
  isRouteActionType,
  REHYDRATED,
  ROUTE_EXIT_TRANSITION_ENDED,
  SCROLL_HINT_REQUIRED_CHANGED,
  SCROLL_HINT_TOGGLE_SCROLLED_DOWN,
  toggleScrolledDownCreator,
  toggleScrollHintDelayElapsedCreator,
  toggleScrollHintSeenCreator,
  toggleScrollHintToggleScrolledDownCreator
} from '../../actions'
import afterFrame from '../../helpers/afterFrame'

let pageYOffset = 0
export const getPageYOffset = () => pageYOffset

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  let disposed = false
  const onScroll = () => {
    if (disposed) {
      return
    }
    const prevPageOffset = pageYOffset
    pageYOffset = window.pageYOffset
    if (prevPageOffset !== pageYOffset) {
      const nextScrollHintScrolledDown = pageYOffset > 60
      if (isScrollHintScrolledDown(store.getState()) !== nextScrollHintScrolledDown) {
        store.dispatch(toggleScrollHintToggleScrolledDownCreator())
      }
      const nextScrolledDown = pageYOffset > document.documentElement.clientHeight * 0.25
      if (isScrolledDown(store.getState()) !== nextScrolledDown) {
        store.dispatch(toggleScrolledDownCreator())
      }
    }
    afterFrame(onScroll)
  }
  afterFrame(onScroll)

  let timeout
  const resetDelay = () => {
    if (hasScrollHintDelayElapsed(store.getState())) {
      store.dispatch(toggleScrollHintDelayElapsedCreator())
    }
    if (isScrollHintSeen(store.getState())) {
      store.dispatch(toggleScrollHintSeenCreator())
    }
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
  }

  const startDelay = () => {
    resetDelay()
    timeout = setTimeout(() => {
      timeout = null
      if (!hasScrollHintDelayElapsed(store.getState())) {
        store.dispatch(toggleScrollHintDelayElapsedCreator())
      }
    }, 3000)
  }

  return next => action => {
    if (action.type === SCROLL_HINT_REQUIRED_CHANGED && action.payload === isScrollHintRequired(store.getState())) {
      return
    }
    if (action.type === REHYDRATED) {
      startDelay()
    }
    if (isRouteActionType(action.type)) {
      resetDelay()
    }
    if (action.type === ROUTE_EXIT_TRANSITION_ENDED) {
      startDelay()
    }
    if (
      action.type === SCROLL_HINT_TOGGLE_SCROLLED_DOWN &&
      isScrollHintScrolledDown(store.getState()) &&
      !isScrollHintSeen(store.getState())
    ) {
      store.dispatch(toggleScrollHintSeenCreator())
    }
    if (module.hot && process.env.NODE_ENV === 'development') {
      if (action.type === 'HMR_RELOADED') {
        startDelay()
      }
      if (action.type === 'HMR_DISPOSED') {
        disposed = true
        if (timeout) {
          clearTimeout(timeout)
          timeout = null
        }
      }
    }
    return next(action)
  }
}
