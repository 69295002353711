import { useCallback, useRef } from 'react'
import qsa from 'dom-helpers/querySelectorAll'
import each from 'lodash/each'
import color from 'color'
import { addColorListener, removeColorListener } from '../../middlewares/view/colors'
import round from '../../helpers/round'

export const getMatchTextColorFilter = textColor => {
  const intensity = round(color(textColor).red() / 255)
  return `grayscale(${intensity}) invert(${intensity})`
}

export default function useMatchTextColorFilter (classNames) {
  const listenerRef = useRef()
  return useCallback((element) => {
    if (element) {
      let elements
      if (classNames) {
        const className = classNames.split(' ')[0]
        elements = qsa(element, `.${className}`)
      } else {
        elements = [element]
      }
      listenerRef.current = textColor => {
        const filter = getMatchTextColorFilter(textColor)
        // Even gsap.quickSetter() isn't as fast as style.setProperty().
        each(elements, element => {
          element.style.setProperty('filter', filter)
        })
      }
      addColorListener(listenerRef.current)
    } else if (listenerRef.current) {
      removeColorListener(listenerRef.current)
      listenerRef.current = null
    }
  }, [classNames])
}
