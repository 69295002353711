/* global ResizeObserver */
import inDOM from 'dom-helpers/canUseDOM'
import pull from 'lodash/pull'
import map from 'lodash/map'
import detectIt from 'detect-it'
import {
  BEGIN_ROUTE_EXIT_TRANSITION,
  PORTFOLIO_MOUNTED,
  PORTFOLIO_UNMOUNTED,
  ROUTE_CONTENT,
  ROUTE_EXIT_TRANSITION_ENDED,
  ROUTE_NOT_FOUND
} from '../../actions'
import { isPortfolioRoute } from '../../selectors'
import getViewportSize from '../../helpers/getViewportSize'
import instantScrollTo from '../../helpers/instantScrollTo'
import gsap from 'gsap'
import { getPageYOffset } from './scroll'
import afterFrame from '../../helpers/afterFrame'

let heroItemIndex = -1
let paused = false
let items = null
let itemHeight
let itemSpacing
let firstItemTopMargin
let targetY

const scrollDeltaWeight = detectIt.primaryInput === 'touch' ? 0.6 : 0.2 // per tick @60fps
const itemTransitionRange = 2.2
const itemFullyTransitionedDuration = 0.4

const awaitNextHeroItemIndexUpdateHandlers = []

export function getHeroItemIndex () {
  return heroItemIndex
}

export function getItemScrollProgress (index, heroItemIndex = getHeroItemIndex()) {
  const i = (heroItemIndex - index) * 2
  return gsap.utils.clamp(0, 1, (i + itemTransitionRange) / (itemTransitionRange * 2))
}

export function getItemTransitionProgress (index, heroItemIndex = getHeroItemIndex()) {
  const i = Math.abs(heroItemIndex - index) * 2
  return gsap.utils.clamp(0, 1, 1 - ((i - itemFullyTransitionedDuration) / (itemTransitionRange - itemFullyTransitionedDuration)))
}

export function awaitNextHeroItemIndexUpdate (handler) {
  awaitNextHeroItemIndexUpdateHandlers.push(handler)
}

export function cancelAwaitNextHeroItemIndexUpdate (handler) {
  pull(awaitNextHeroItemIndexUpdateHandlers, handler)
}

function dispatchUpdatedHeroItemIndex (nextHeroItemIndex, deltaTime) {
  const delta = nextHeroItemIndex - heroItemIndex
  let weight = 1
  if (deltaTime) {
    const framesPassed = (deltaTime / 1000) / (1 / 60)
    weight = Math.min(scrollDeltaWeight * framesPassed, 1)
  }
  heroItemIndex += delta * weight

  while (awaitNextHeroItemIndexUpdateHandlers.length) {
    const handler = awaitNextHeroItemIndexUpdateHandlers.shift()
    handler(heroItemIndex)
  }
}

function calculateHeroItemIndexOrPageYOffset ({ heroItemIndex, pageYOffset }) {
  if (!items || !targetY) {
    return -1
  }
  const currentPageYOffset = getPageYOffset()
  const firstItemTop = firstItemTopMargin - currentPageYOffset
  if (heroItemIndex !== undefined) {
    const centreY = heroItemIndex * itemSpacing
    const nextFirstItemTop = targetY - centreY - itemHeight / 2
    return currentPageYOffset + (firstItemTop - nextFirstItemTop)
  } else {
    const adjustedFirstItemTop = firstItemTop - (pageYOffset === undefined ? 0 : (pageYOffset - currentPageYOffset))
    const centreY = targetY - adjustedFirstItemTop - itemHeight / 2
    return centreY / itemSpacing
  }
}

export function calculateHeroItemIndex (pageYOffset) {
  return calculateHeroItemIndexOrPageYOffset({ pageYOffset })
}

export function calculatePageYOffsetForHeroItemIndex (heroItemIndex) {
  return calculateHeroItemIndexOrPageYOffset({ heroItemIndex })
}

export function setHeroItemIndex (nextHeroItemIndex) {
  heroItemIndex = nextHeroItemIndex
  paused = true
  afterFrame(() => {
    const pageYOffset = calculatePageYOffsetForHeroItemIndex(heroItemIndex)
    instantScrollTo(pageYOffset)
    dispatchUpdatedHeroItemIndex(heroItemIndex)
    paused = false
  })
}

function onTick (time, deltaTime) {
  if (!items || paused) {
    return
  }
  const heroItemIndex = calculateHeroItemIndex()
  dispatchUpdatedHeroItemIndex(heroItemIndex, deltaTime)
}

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  let ro
  const mount = (elements) => {
    items = map(elements, 'containerEl')
    ro = new ResizeObserver(() => {
      const firstItemRect = items[0].getBoundingClientRect()
      const secondItemRect = items[1].getBoundingClientRect()
      const viewportHeight = getViewportSize().height
      const pageYOffset = window.pageYOffset
      firstItemTopMargin = firstItemRect.top + pageYOffset
      itemHeight = firstItemRect.height
      itemSpacing = (secondItemRect.top - firstItemRect.top) || (viewportHeight * 0.1)
      targetY = viewportHeight * 0.52
    })
    ro.observe(items[0].parentNode)
  }

  const unmount = () => {
    items = null
    itemHeight = undefined
    itemSpacing = undefined
    firstItemTopMargin = undefined
    targetY = undefined
    if (ro) {
      ro.disconnect()
      ro = null
    }
    disengage()
  }

  const engage = () => {
    gsap.ticker.add(onTick)
  }

  const disengage = () => {
    gsap.ticker.remove(onTick)
  }

  return next => action => {
    switch (action.type) {
      case PORTFOLIO_MOUNTED:
        mount(action.payload)
        if (isPortfolioRoute(store.getState())) {
          engage()
        }
        break
      case PORTFOLIO_UNMOUNTED:
        unmount()
        break
      case BEGIN_ROUTE_EXIT_TRANSITION:
        disengage()
        break
      case ROUTE_EXIT_TRANSITION_ENDED:
        if (isPortfolioRoute(store.getState())) {
          engage()
        }
        break
    }

    const ret = next(action)

    switch (action.type) {
      case ROUTE_CONTENT:
      case ROUTE_NOT_FOUND:
        if (isPortfolioRoute(store.getState())) {
          engage()
        } else {
          disengage()
        }
        break
    }
    return ret
  }
}
