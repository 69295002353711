import inDOM from 'dom-helpers/canUseDOM'
import gsap from 'gsap'
import map from 'lodash/map'
import {
  BEGIN_ROUTE_EXIT_TRANSITION,
  PORTFOLIO_MOUNTED,
  PORTFOLIO_UNMOUNTED,
  REHYDRATED
} from '../../actions'
import {
  getCurrentPageType,
  getCurrentPortfolioItemIndex,
  getFocusThreshold,
  isPortfolioOrWorkPageType,
  isWorkPageType
} from '../../selectors'
import { applyItemScrollTransitionProps } from './portfolioScroll'
import getSavedHeroItemIndex from '../../helpers/getSavedHeroItemIndex'
import { getItemScrollProgress, getItemTransitionProgress } from '../view/portfolioScroll'
import pick from 'lodash/pick'

const duration = 0.5
let tweeners = null

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  const makeTweeners = (items) => map(items, (item, index) => ({
    scrollProgress: getItemScrollProgress(index),
    transitionProgress: getItemTransitionProgress(index),

    getFromScroll () {
      return {
        scrollProgress: getItemScrollProgress(index),
        transitionProgress: getItemTransitionProgress(index)
      }
    },

    onUpdate () {
      const focusThreshold = getFocusThreshold(store.getState())
      const { scrollProgress, transitionProgress } = this
      applyItemScrollTransitionProps({
        alignment: index % 2 === 0 ? 'left' : 'right',
        scrollProgress,
        transitionProgress,
        focusThreshold,
        ...item
      })
    }
  }))

  let prevSelectedIndex = -1
  let selectedIndex = -1
  let prevPageType
  let pageType

  const beginTransition = () => {
    if (!tweeners) {
      return
    }
    const wasWork = isWorkPageType(prevPageType)
    const isWork = isWorkPageType(pageType)
    const internal = isPortfolioOrWorkPageType(prevPageType) && isPortfolioOrWorkPageType(pageType)
    const savedHeroItemIndex = getSavedHeroItemIndex(prevSelectedIndex)

    for (let i = 0; i < tweeners.length; ++i) {
      const tweener = tweeners[i]
      const selected = i === selectedIndex
      const wasSelected = i === prevSelectedIndex
      if (!internal) {
        if (isWork) {
          if (!selected) {
            const { scrollProgress } = tweener.getFromScroll()
            tweener.scrollProgress = scrollProgress >= 0.5 ? 1 : 0
            tweener.transitionProgress = 0
            tweener.onUpdate()
          }
        }
      } else if (selected === wasSelected && isWork !== wasWork) {
        let from
        let scrollProgress
        let transitionProgress
        let ease

        if (isWork) {
          // this item just needs to get out of the viewport
          from = tweener.getFromScroll()
          scrollProgress = from.scrollProgress >= 0.5 ? 1 : 0
          transitionProgress = 0
          ease = 'power2.in'
        } else {
          from = pick(tweener, ['scrollProgress', 'transitionProgress'])
          scrollProgress = getItemScrollProgress(i, savedHeroItemIndex)
          transitionProgress = getItemTransitionProgress(i, savedHeroItemIndex)
          ease = 'power2.out'
        }

        gsap.fromTo(tweener, from, {
          scrollProgress,
          transitionProgress,
          duration: duration * gsap.utils.clamp(0, 1, Math.abs(scrollProgress - from.scrollProgress) / 0.1),
          onUpdate: tweener.onUpdate.bind(tweener),
          ease
        })
      }
    }
  }

  const navigated = () => {
    prevSelectedIndex = selectedIndex
    selectedIndex = getCurrentPortfolioItemIndex(store.getState())
    prevPageType = pageType
    pageType = getCurrentPageType(store.getState())
  }

  return next => action => {
    switch (action.type) {
      case REHYDRATED:
        navigated()
        break
      case PORTFOLIO_MOUNTED:
        tweeners = makeTweeners(action.payload)
        break
      case PORTFOLIO_UNMOUNTED:
        if (tweeners) {
          gsap.killTweensOf(tweeners)
          tweeners = null
        }
        break
      case BEGIN_ROUTE_EXIT_TRANSITION:
        navigated()
        if (isPortfolioOrWorkPageType(prevPageType) || isPortfolioOrWorkPageType(pageType)) {
          beginTransition()
        }
        break
      // no default
    }

    if (module.hot && process.env.NODE_ENV === 'development') {
      if (action.type === 'HMR_RELOADED') {
        navigated()
      }
    }

    return next(action)
  }
}
