import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getCvEducationItems, getCvEducationSlug, getCvEducationTitle } from '../../selectors'
import Section from './Section'
import Highlights from './Highlights'
import RichText from '../RichText'

const Education = ({ className }) => {
  const slug = useSelector(getCvEducationSlug)
  const title = useSelector(getCvEducationTitle)
  const items = useSelector(getCvEducationItems)
  const classes = useStyles()
  const qualificationSerializers = useMemo(() => ({
    types: {
      block: ({ children }) => (
        <h5 className={classes.qualification}>{children}</h5>
      )
    }
  }), [classes])
  return (
    <Section className={className} id={slug} title={title}>
      {items.map(({ year, qualification, highlights }, i) => (
        <section key={i} className={classes.item}>
          <h4>{year}</h4>
          <RichText content={qualification} serializers={qualificationSerializers} />
          {highlights && <Highlights highlights={highlights} />}
        </section>
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  item: {
    marginBottom: 50
  },
  qualification: {
    marginBottom: '1em'
  }
}, { name: 'Education' })

export default Education
