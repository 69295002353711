/* global ResizeObserver */
import inDOM from 'dom-helpers/canUseDOM'
import gsap, { Power2 } from 'gsap'
import EasePack from 'gsap/EasePack'
import each from 'lodash/each'
import getViewportSize from '../../helpers/getViewportSize'
import {
  getFocusThreshold,
  isPortfolioRoute
} from '../../selectors'
import {
  BEGIN_ROUTE_EXIT_TRANSITION,
  PORTFOLIO_MOUNTED,
  PORTFOLIO_UNMOUNTED,
  ROUTE_EXIT_TRANSITION_ENDED
} from '../../actions'
import { getItemScrollProgress, getItemTransitionProgress } from '../view/portfolioScroll'

gsap.registerPlugin(EasePack.ExpoScaleEase)

let items = null

const minScale = 0.5
const minRotationY = 5
const maxRotationY = 30
const minOpacity = 0.4
const ease = gsap.parseEase(`expoScale(${minScale}, 1, power2.inOut)`)

export function calculateItemTransitionProps ({ el, alignment, scrollProgress, transitionProgress }) {
  const { width: windowWidth, height: windowHeight } = getViewportSize()
  const { width, height, titleHeight } = el.portfolioScrollMeasurements || {}
  const itemWidth = width * 0.8
  const itemHeight = height * 0.8
  const halfWindowHeight = windowHeight / 2
  const halfItemHeight = itemHeight / 2
  const y = gsap.utils.interpolate(halfWindowHeight + halfItemHeight, -halfWindowHeight - halfItemHeight, scrollProgress)

  const t = ease(transitionProgress)
  const scale = minScale + t * (1 - minScale)
  const alignmentSign = alignment === 'left' ? 1 : -1
  const rotationY = (minRotationY + (1 - t) * (maxRotationY - minRotationY)) * alignmentSign
  const opacity = minOpacity + t * (1 - minOpacity)
  const filter = `grayscale(${1 - t})`
  const transformPerspective = Math.min(windowWidth, 600)

  const props = {
    y,
    scale,
    opacity,
    filter,
    rotationY,
    transformPerspective
  }

  if (titleHeight) {
    const scaledWidth = scale * itemWidth
    const x = (scaledWidth - itemWidth) / 2 * -alignmentSign
    props.title = {
      x,
      y: y - titleHeight / 2 // centred vertically
    }
  }

  return props
}

export function applyItemScrollTransitionProps ({
  alignment, scrollProgress, transitionProgress, focusThreshold,
  containerEl: el, titleEl, titleEtchEl, titleTypeEl, titleTypeEtchEl, awardsEl
}) {
  const heroEl = el.childNodes[0]
  const clipEl = heroEl.childNodes[0]
  const imgEl = clipEl.childNodes[0]

  if (!(el && el.portfolioScrollMeasurements && heroEl && imgEl && titleEl && awardsEl)) {
    return
  }

  if (scrollProgress === 0 || scrollProgress === 1) {
    // optimisation: quit early if it's offscreen
    gsap.set(el, { visibility: 'hidden' })
    gsap.set([titleEtchEl, titleTypeEtchEl], { opacity: 0 })
    return
  } else {
    gsap.set([el, awardsEl], { visibility: 'visible' })
  }

  const {
    y,
    scale,
    opacity,
    filter,
    rotationY,
    transformPerspective,
    title: titleProps
  } = calculateItemTransitionProps({
    el, titleEl, alignment, scrollProgress, transitionProgress
  })

  gsap.set(heroEl, { y })
  gsap.set(clipEl, {
    scale,
    opacity,
    filter,
    rotationY,
    transformPerspective
  })
  gsap.set(imgEl, {
    scaleX: 1,
    scaleY: 1
    // clearing transforms forces gsap to re-read the element’s computed style, so just reset scale to 1
    // clearProps: 'scaleX,scaleY'
  })
  gsap.set(titleEl, {
    ...titleProps
  })

  gsap.set([titleEtchEl, titleTypeEtchEl], {
    opacity: Power2.easeIn(transitionProgress)
  })

  const focused = transitionProgress >= focusThreshold
  if (titleTypeEl.toggled !== focused) {
    titleTypeEl.toggled = focused
    gsap.to(titleTypeEl, { duration: 0.2, opacity: focused ? 1 : 0 })
  }

  if (awardsEl.toggled !== focused) {
    const initialRender = awardsEl.toggled === undefined
    awardsEl.toggled = focused
    if (awardsEl.tween === undefined) {
      const items = Array.from(awardsEl.querySelectorAll('li'))
      if (items.length) {
        awardsEl.tween = gsap.fromTo(
          items,
          { autoAlpha: 0, scale: 0.4 },
          { autoAlpha: 1, scale: 1, duration: 0.2, ease: 'power3.in', stagger: -0.1 }
        )
        if (!focused) {
          if (initialRender) {
            awardsEl.tween.pause()
          } else {
            awardsEl.tween.timeScale(1.5).reverse(0)
          }
        }
      } else {
        awardsEl.tween = null
      }
    } else if (awardsEl.tween) {
      if (focused) {
        awardsEl.tween.timeScale(1).play()
      } else {
        awardsEl.tween.timeScale(1.5).reverse()
      }
    }
  }
}

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  function onTick () {
    if (!items) {
      return
    }
    const focusThreshold = getFocusThreshold(store.getState())
    for (let i = 0; i < items.length; ++i) {
      const scrollProgress = getItemScrollProgress(i)
      const transitionProgress = getItemTransitionProgress(i)
      applyItemScrollTransitionProps({
        alignment: i % 2 === 0 ? 'left' : 'right',
        scrollProgress,
        transitionProgress,
        focusThreshold,
        ...items[i]
      })
    }
  }

  const engage = () => {
    gsap.ticker.add(onTick)
  }

  const disengage = () => {
    gsap.ticker.remove(onTick)
  }

  let ro
  const mount = (elements) => {
    items = elements
    ro = new ResizeObserver(() => {
      // measure up all the items
      each(items, ({ containerEl: el, titleEl }) => {
        const measurements = {}
        const { width, height } = el.getBoundingClientRect()
        measurements.width = width
        measurements.height = height
        if (titleEl) {
          measurements.titleHeight = titleEl.getBoundingClientRect().height
        }
        el.portfolioScrollMeasurements = measurements
      })
    })
    ro.observe(items[0].containerEl)
    if (isPortfolioRoute(store.getState())) {
      engage()
    }
  }

  const unmount = () => {
    items = null
    if (ro) {
      ro.disconnect()
      ro = null
    }
    disengage()
  }

  return next => action => {
    switch (action.type) {
      case PORTFOLIO_MOUNTED:
        mount(action.payload)
        break
      case PORTFOLIO_UNMOUNTED:
        unmount()
        break
      case BEGIN_ROUTE_EXIT_TRANSITION:
        disengage()
        break
      case ROUTE_EXIT_TRANSITION_ENDED:
        if (isPortfolioRoute(store.getState())) {
          engage()
        }
        break
        // no default
    }

    return next(action)
  }
}
