import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import last from 'lodash/last'
import Link from '../Link'
import theme, { srOnly, textMaxWidth } from '../../styles/theme'
import RichText from '../RichText'
import useMatchTextColorFilter from '../hooks/useMatchTextColorFilter'

// This looks dodgy for server rendering, but is fine in practice - after the first render on a process, subsequent
// renders will just use the stored values and nextClientIsOdd will be ignored.
let nextClientIsOdd = true
const clientOddOrEvenFlags = {}

const Client = ({ className, client }) => {
  const classes = useStyles()

  if (clientOddOrEvenFlags[client.slug] === undefined) {
    clientOddOrEvenFlags[client.slug] = nextClientIsOdd
    nextClientIsOdd = !nextClientIsOdd
  }

  const logo = last(get(client, ['logo', 'sizes']))

  const logoRef = useMatchTextColorFilter()

  return (
    <aside className={cn(className, classes.container, clientOddOrEvenFlags[client.slug] && classes.odd)}>
      <h4 className={classes.name}>
        {logo ? (
          <>
            <img className={classes.logo} src={logo.url} alt={client.title} width={logo.width} height={logo.height} ref={logoRef} />
            <span className={classes.srOnly}>{client.title}</span>
          </>
        ) : client.title}
      </h4>
      <h5 className={classes.link}><Link link={client.link} /></h5>
      <RichText content={client.description} />
    </aside>
  )
}

const useStyles = createUseStyles({
  srOnly,
  container: {
    width: '20em',
    marginBottom: 50,
    [theme.breakpoints.only('lg')]: {
      fontSize: 12
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  odd: {
    [theme.breakpoints.up('xxl')]: {
      textAlign: 'right',
      marginLeft: theme.containerMaxWidth - textMaxWidth - 120 - 300
    }
  },
  name: {
    fontSize: 20,
    marginBottom: '0.5em'
  },
  link: {
    fontFamily: theme.fonts.body,
    fontWeight: theme.fonts.bodyFontWeight,
    fontSize: 'inherit'
  },
  logo: {}
}, { name: 'Client' })

export default Client
