import inDOM from 'dom-helpers/canUseDOM'
import { createStore, applyMiddleware as defaultApplyMiddleware, compose as defaultCompose, combineReducers } from 'redux'
import { connectRoutes } from 'redux-first-router'
import cloneDeepWith from 'lodash/cloneDeepWith'
import isElement from 'lodash/isElement'
import reducers from './reducers'
import middlewares from './middlewares'
import routesMap from './routesMap'

let applyMiddleware = defaultApplyMiddleware

if (module.hot && process.env.NODE_ENV === 'development') {
  // Adds store.replaceMiddleware for hot-reloadable middlewares. Based on code by iMoses:
  // https://github.com/reduxjs/react-redux/issues/602
  applyMiddleware = function replaceableMiddleware (...middlewares) {
    return createStore => {
      return (reducer, preloadedState, enhancer) => {
        const store = createStore(reducer, preloadedState, enhancer)

        let dispatch = store.dispatch

        const middlewareAPI = {
          getState: store.getState,
          dispatch: action => dispatch(action)
        }

        applyMiddleware(...middlewares)

        return { ...store, dispatch: action => dispatch(action), replaceMiddleware: applyMiddleware }

        function applyMiddleware (...middlewares) {
          middlewares = middlewares.map(middleware => middleware(middlewareAPI))
          dispatch = defaultCompose(...middlewares)(store.dispatch)
        }
      }
    }
  }
}

export default function ({ preloadedState, initialEntries } = {}) {
  let compose = defaultCompose
  /* global __REDUX_DEVTOOLS_EXTENSION_COMPOSE__:false */
  if (typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
    // if you install the Redux Devtools extension (see https://github.com/zalmoxisus/redux-devtools-extension)
    // then this will connect to the dev tools and allow you to inspect Redux state
    compose = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer: (action) => (
        action.type && action.type.endsWith('_MOUNTED')
          ? ({
            ...action,
            payload: cloneDeepWith(action.payload, value => isElement(value) ? '<<DOM Node>>' : undefined)
          })
          : action
      )
    })
  }

  let restoreScroll
  if (inDOM) {
    restoreScroll = require('redux-first-router-restore-scroll').default({
      // We’ll restore the scroll position as part of the transitions in the Content component.
      manual: true
    })
  }
  const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
    thunk: routerThunk
  } = connectRoutes(
    routesMap, {
      initialEntries,
      restoreScroll
    })

  const rootReducer = combineReducers({ ...reducers, location: routerReducer })
  const enhancers = compose(
    routerEnhancer,
    applyMiddleware(routerMiddleware, ...middlewares)
  )

  const store = createStore(rootReducer, preloadedState, enhancers)

  if (module.hot && process.env.NODE_ENV === 'development') {
    // Enable Webpack hot module replacement for reducers and middlewares
    module.hot.accept('./reducers', () => {
      const rootReducer = combineReducers({ ...reducers, location: routerReducer })
      store.replaceReducer(rootReducer)
    })
    module.hot.accept('./middlewares', () => {
      store.dispatch({ type: 'HMR_DISPOSED' })
      store.replaceMiddleware(routerMiddleware, ...middlewares)
      store.dispatch({ type: 'HMR_RELOADED' })
    })
  }

  return { store, routerThunk }
}
