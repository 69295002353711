/* global ResizeObserver */
import inDOM from 'dom-helpers/canUseDOM'
import get from 'lodash/get'
import gsap from 'gsap'
import { updateScroll } from 'redux-first-router'
import {
  BEGIN_ROUTE_EXIT_TRANSITION,
  beginRouteExitTransitionCreator,
  isRouteActionType,
  PORTFOLIO_MOUNTED,
  PORTFOLIO_UNMOUNTED,
  REHYDRATED,
  ROUTE_EXIT_TRANSITION_ENDED,
  routeExitTransitionEndedCreator,
  WORK_MOUNTED,
  WORK_UNMOUNTED
} from '../../actions'
import getSavedHeroItemIndex from '../../helpers/getSavedHeroItemIndex'
import { calculateItemTransitionProps } from './portfolioScroll'
import { getItemScrollProgress, getItemTransitionProgress, setHeroItemIndex } from '../view/portfolioScroll'
import {
  getCurrentPageType,
  getCurrentPortfolioItemIndex,
  isPortfolioOrWorkPageType,
  isWorkRoute
} from '../../selectors'

const duration = 0.5

let workEl = null
let portfolioItems = null
let portfolioHeroObserver
let workImagePlaceholderObserver
let incomingPortfolioItemFlipProps
const onMeasurementsReady = []

function workMounted (element) {
  workEl = element
  const imgPlaceholderEl = workEl && workEl.childNodes[0]
  if (imgPlaceholderEl) {
    workImagePlaceholderObserver = new ResizeObserver((entries) => {
      const { width: workImagePlaceholderWidth, height: workImagePlaceholderHeight } =
        get(entries, [0, 'contentRect'], {})
      updateIncomingPortfolioItemFlipProps({ workImagePlaceholderWidth, workImagePlaceholderHeight })
    })
    workImagePlaceholderObserver.observe(imgPlaceholderEl)
  }
}

function workUnmounted () {
  if (workImagePlaceholderObserver) {
    workImagePlaceholderObserver.disconnect()
    workImagePlaceholderObserver = null
  }
  workEl = null
}

function portfolioMounted (items) {
  portfolioItems = items
  const containerEl = get(portfolioItems, [0, 'containerEl'])
  const heroEl = containerEl && containerEl.childNodes[0]
  if (heroEl) {
    portfolioHeroObserver = new ResizeObserver((entries) => {
      // Note: the values provided here (contentRect) are not affected by CSS transforms, which is
      // exactly what we want.
      const { width: portfolioHeroWidth, height: portfolioHeroHeight } =
        get(entries, [0, 'contentRect'], {})
      const portfolioHeroX = (heroEl.offsetLeft + portfolioHeroWidth / 2) - document.documentElement.clientWidth / 2
      updateIncomingPortfolioItemFlipProps({ portfolioHeroX, portfolioHeroWidth, portfolioHeroHeight })
    })
    portfolioHeroObserver.observe(heroEl)
  }
}

function portfolioUnmounted () {
  if (portfolioHeroObserver) {
    portfolioHeroObserver.disconnect()
    portfolioHeroObserver = null
  }
  portfolioItems = null
}

function callWhenMeasurementsReady (callback) {
  if (incomingPortfolioItemFlipProps) {
    callback()
  } else if (onMeasurementsReady.indexOf(callback) < 0) {
    onMeasurementsReady.push(callback)
  }
}

const needsMeasurements = func => () => callWhenMeasurementsReady(func)

const updateIncomingPortfolioItemFlipProps = (() => {
  const measurements = {}
  return (newMeasurements) => {
    const {
      portfolioHeroX, portfolioHeroWidth, portfolioHeroHeight,
      workImagePlaceholderWidth, workImagePlaceholderHeight
    } = Object.assign(measurements, newMeasurements)

    if (!portfolioHeroWidth || !workImagePlaceholderWidth) {
      return
    }

    // Work out how to transform the portfolio item position (its natural CSS-based position) to match the
    // work page background image position, shape and size.
    const scaleX = workImagePlaceholderWidth / portfolioHeroWidth
    const scaleY = workImagePlaceholderHeight / portfolioHeroHeight
    const x = -portfolioHeroX / scaleX
    // fix the aspect ratio of the image
    const aspect = portfolioHeroWidth / portfolioHeroHeight
    const workItemAspect = workImagePlaceholderWidth / workImagePlaceholderHeight

    incomingPortfolioItemFlipProps = {
      hero: {
        x,
        y: 0, // Both images are centred vertically on the screen, so y translate is 0.
        scaleX,
        scaleY
      },
      clip: {
        opacity: 0.06,
        filter: 'grayscale(1)',
        rotationY: 0,
        scale: 1
      },
      img: {
        scaleX: workItemAspect < aspect ? aspect / workItemAspect : 1,
        scaleY: workItemAspect < aspect ? 1 : workItemAspect / aspect
      },
      title: {}
    }

    while (onMeasurementsReady.length) {
      const notify = onMeasurementsReady.shift()
      notify()
    }
  }
})()

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  let prevSelectedIndex = -1
  let selectedIndex = -1
  let prevPageType
  let pageType
  const beginRouteExitTransition = () => store.dispatch(beginRouteExitTransitionCreator())
  const endRouteExitTransition = () => store.dispatch(routeExitTransitionEndedCreator())

  const getSelectedOrPrevIndex = () => selectedIndex >= 0 ? selectedIndex : prevSelectedIndex

  const getElements = () => {
    const portfolioItem = portfolioItems && portfolioItems[getSelectedOrPrevIndex()]
    if (!portfolioItem) {
      return {}
    }
    const portfolioItemEl = portfolioItem.containerEl
    const heroEl = portfolioItemEl.childNodes[0]
    const clipEl = heroEl.childNodes[0]
    const imgEl = clipEl.childNodes[0]
    const imgPlaceholderEl = workEl.childNodes[0]
    return {
      ...portfolioItem,
      portfolioItemEl,
      heroEl,
      clipEl,
      imgEl,
      imgPlaceholderEl
    }
  }

  const calculateOutgoingPortfolioItemFlipProps = () => {
    const { containerEl } = getElements()
    const savedHeroItemIndex = getSavedHeroItemIndex(prevSelectedIndex)
    const {
      y,
      scale,
      opacity,
      filter,
      rotationY,
      transformPerspective,
      title
    } = calculateItemTransitionProps({
      el: containerEl,
      alignment: prevSelectedIndex % 2 === 0 ? 'left' : 'right',
      scrollProgress: getItemScrollProgress(prevSelectedIndex, savedHeroItemIndex),
      transitionProgress: getItemTransitionProgress(prevSelectedIndex, savedHeroItemIndex)
    })

    return {
      hero: {
        x: 0,
        y,
        scaleX: 1,
        scaleY: 1
      },
      clip: {
        opacity,
        filter,
        rotationY,
        scale,
        transformPerspective
      },
      img: {
        scaleX: 1,
        scaleY: 1
      },
      title
    }
  }

  const repositionBackgroundImage = needsMeasurements(() => {
    const { heroEl, clipEl, imgEl, titleEl, awardsEl } = getElements()
    if (!heroEl) {
      return
    }
    const { hero: heroVars, clip: clipVars, img: imgVars } = incomingPortfolioItemFlipProps
    gsap.set(heroEl, heroVars)
    gsap.set(clipEl, clipVars)
    gsap.set(imgEl, imgVars)
    gsap.set(titleEl, { autoAlpha: 0 })
    gsap.set(awardsEl, { clearProps: 'visibility' })
  })

  const transition = needsMeasurements(() => {
    const { portfolioItemEl, heroEl, clipEl, imgEl, titleEl, awardsEl } = getElements()
    if (!workEl || !portfolioItemEl) {
      return
    }
    const internal = isPortfolioOrWorkPageType(prevPageType) && isPortfolioOrWorkPageType(pageType)
    const outgoing = selectedIndex < 0

    if (!internal) {
      if (!outgoing) {
        // Work: show work content
        gsap.timeline()
          .fromTo(workEl, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.7 })
          .fromTo(portfolioItemEl, { pointerEvents: 'none', autoAlpha: 0 }, { autoAlpha: 1, duration: 0.7 })
        repositionBackgroundImage()
      }
    } else {
      if (prevSelectedIndex !== selectedIndex) {
        if (outgoing) {
          // Portfolio: transition from the work item content back to the portfolio item
          setHeroItemIndex(getSavedHeroItemIndex(prevSelectedIndex))
        }
        const { hero: heroVars, clip: clipVars, img: imgVars, title: titleVars } = outgoing
          ? calculateOutgoingPortfolioItemFlipProps()
          : incomingPortfolioItemFlipProps

        const timeline = gsap.timeline({
          onStart: () => {
            if (outgoing) {
              // TODO exit transitions
              gsap.set(workEl, { clearProps: 'visibility,pointerEvents' }) // hidden is set in css
              // The below doesn’t work because of the scroll position being adjusted, which effectively hides the
              // content.
              // gsap.fromTo(workEl, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.7 })
            } else {
              gsap.set(portfolioItemEl, { pointerEvents: 'none' })
            }
          },
          onComplete: () => {
            endRouteExitTransition()
            if (outgoing) {
              gsap.set(portfolioItemEl, { clearProps: 'pointerEvents' })
            } else {
              // TODO enter transitions
              gsap.fromTo(workEl, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.7, clearProps: 'pointerEvents' })
            }
          }
        })
          .to(heroEl, {
            ...heroVars,
            duration
          }, 0)

          .to(clipEl, {
            ...clipVars,
            duration
          }, 0)

          .to(imgEl, {
            ...imgVars,
            duration
          }, 0)

        beginRouteExitTransition()

        if (outgoing) {
          timeline
            .set(titleEl, titleVars, 0)
            .to(titleEl, {
              autoAlpha: 1,
              duration: duration * 0.8
            }, duration * 0.9)
          if (awardsEl.tween) {
            timeline.set(awardsEl, { clearProps: 'visibility' }, 0)
            timeline.call(() => {
              awardsEl.tween.timeScale(0.75).play(0)
            }, null, duration * 0.9)
          }
        } else {
          gsap.killTweensOf(titleEl)
          timeline.set(titleEl, { autoAlpha: 0, ...titleVars }, 0)
          timeline.set(awardsEl, { clearProps: 'visibility' }, 0)
          timeline.pause()
          updateScroll().then(() => {
            timeline.play()
          })
        }
      }
    }
  })

  const navigated = () => {
    prevSelectedIndex = selectedIndex
    selectedIndex = getCurrentPortfolioItemIndex(store.getState())
    prevPageType = pageType
    pageType = getCurrentPageType(store.getState())
  }

  return next => action => {
    switch (action.type) {
      case REHYDRATED:
        navigated()
        break
      case WORK_MOUNTED:
        workMounted(action.payload)
        transition()
        break
      case WORK_UNMOUNTED:
        workUnmounted()
        break
      case PORTFOLIO_MOUNTED:
        portfolioMounted(action.payload)
        transition()
        break
      case PORTFOLIO_UNMOUNTED:
        portfolioUnmounted()
        break
      case BEGIN_ROUTE_EXIT_TRANSITION:
        window.removeEventListener('resize', repositionBackgroundImage)
        break
      case ROUTE_EXIT_TRANSITION_ENDED:
        if (isWorkRoute(store.getState())) {
          window.addEventListener('resize', repositionBackgroundImage)
        }
      // no default
    }

    const ret = next(action)

    if (isRouteActionType(action.type)) {
      navigated()
      if (isPortfolioOrWorkPageType(prevPageType) || isPortfolioOrWorkPageType(pageType)) {
        transition()
      }
    }

    if (module.hot && process.env.NODE_ENV === 'development') {
      if (action.type === 'HMR_RELOADED') {
        navigated()
      } else if (action.type === 'HMR_DISPOSED') {
        window.removeEventListener('resize', repositionBackgroundImage)
      }
    }

    return ret
  }
}
