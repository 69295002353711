import { useRef, useEffect, useCallback, useState } from 'react'
import gsap from 'gsap'
import SplitText from 'gsap/SplitText'
import { useSelector } from 'react-redux'
import qsa from 'dom-helpers/querySelectorAll'
import map from 'lodash/map'
import each from 'lodash/each'
import { isPortfolioRoute, isPreHydrate, isRouteExitTransitionInProgress, isScrolledDown } from '../../selectors'

gsap.registerPlugin(SplitText)

const delay = 1
const isVisible = state => isPortfolioRoute(state) && !isScrolledDown(state) && !isPreHydrate(state) && !isRouteExitTransitionInProgress(state)

function useDebouncedValue (value) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedValue(value)
    }, 50)
    return () => {
      clearTimeout(timeout)
    }
  }, [value])

  return debouncedValue
}

function createWordColorChip () {
  const chip = document.createElement('div')
  chip.style.backgroundColor = 'currentColor'
  chip.style.position = 'absolute'
  chip.style.left = 0
  chip.style.top = 0
  chip.style.width = '100%'
  chip.style.height = '100%'
  chip.style.opacity = 0
  return chip
}

export default function useSiteTitleTransition (textClassName) {
  const rawVisible = useSelector(isVisible)
  const visible = useDebouncedValue(rawVisible)

  const locals = useRef({ firstRun: true }).current

  const ref = useCallback((el) => {
    if (el) {
      const text = qsa(el, `.${textClassName}`)
      locals.split = new SplitText(text, { type: 'words' })
      const words = map(locals.split.words, word => {
        const chip = createWordColorChip()
        word.appendChild(chip)
        word.colorChip = chip
        return gsap.timeline({ paused: true })
          .from(word, { autoAlpha: 0, duration: 0.2 }, 0)
          .from(chip, {
            autoAlpha: 1,
            duration: 0.4,
            ease: 'power3.in'
          }, 0)
      })
      locals.timeline = gsap.timeline({ paused: true })
        .to(words, {
          progress: 1,
          duration: words[0].duration(),
          stagger: { from: 'random', amount: 0.4 }
        }, delay)
      gsap.set(el, { visibility: 'visible' })
    } else if (locals.split) {
      locals.timeline.kill()
      delete locals.timeline
      each(locals.split.words, word => {
        word.removeChild(word.colorChip)
        delete word.colorChip
      })
      locals.split.revert()
      delete locals.split
    }
  }, [locals])

  useEffect(() => {
    if (!locals.timeline) {
      return
    }

    if (visible) {
      if (locals.firstRun) {
        locals.firstRun = false
      }
      locals.timeline.timeScale(1).play()
    } else if (!locals.firstRun) {
      locals.timeline.timeScale(2.5).reverse()
    }
  }, [visible])

  return ref
}
