import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import Logo from './Logo'
import theme from '../../styles/theme'
import { getTopLevelPageSlugs, isDesktopSelector, isPreHydrate } from '../../selectors'
import Horizontal from './Horizontal'
import Secondary from './Secondary'
import Menu from './Menu'
import Hamburger from './Hamburger'
import useMenuFromHamburgerTransition from './hooks/useMenuFromHamburgerTransition'
import NavRouteLink from './NavRouteLink'

const Nav = ({ className }) => {
  const classes = useStyles()
  const desktop = useSelector(isDesktopSelector)
  const preHydrate = useSelector(isPreHydrate)
  const slugs = useSelector(getTopLevelPageSlugs)
  const { hamburgerRef, menuRef } = useMenuFromHamburgerTransition()
  const links = slugs.map((slug) => (
    <NavRouteLink key={slug} slug={slug} />
  ))
  return (
    <nav className={cn(classes.container, className)} data-nosnippet>
      <Logo className={classes.logo} />
      {(desktop || preHydrate) && <Horizontal className={classes.horizontal} rightAlignLastItem>{links}</Horizontal>}
      {(desktop || preHydrate) && <Secondary className={classes.secondary} />}
      {(!desktop || preHydrate) && (
        <>
          {preHydrate && (
            // To support toggling the hamburger menu on mobile in no-js mode, this checkbox will be toggled via a
            // <label> inside the Hamburger component. The css selector #hamburger-toggle:checked ~ ... can then
            // toggle things via CSS.
            <input className={classes.preHydrateHamburgerToggle} type='checkbox' id='hamburger-toggle' />
          )}
          <Menu className={classes.menu} ref={menuRef}>{links}</Menu>
          <Hamburger className={classes.hamburger} ref={hamburgerRef} />
        </>
      )}
    </nav>
  )
}

const paddingMobile = theme.padding.nav.left
const paddingDesktop = [theme.padding.md.nav.top, theme.padding.md.nav.left]

const useStyles = createUseStyles({
  container: {
    fontSize: 18,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    padding: paddingMobile,
    userSelect: 'none',
    zIndex: theme.zIndex.nav,
    [theme.breakpoints.up('md')]: {
      padding: paddingDesktop
    }
  },
  logo: {
    position: 'absolute',
    left: paddingMobile,
    top: 15,
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      left: paddingDesktop[0],
      top: paddingDesktop[0]
    }
  },
  horizontal: {
    [theme.breakpoints.down('sm')]: {
      // Hide on mobile for no-js case.
      display: 'none'
    }
  },
  preHydrateHamburgerToggle: {
    // This does not need to be in the flow at all - it will be toggled "remotely" via a <label>
    display: 'none'
  },
  hamburger: {
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.up('md')]: {
      // Hide on desktop for no-js case.
      display: 'none'
    }
  },
  menu: {
    [theme.breakpoints.up('md')]: {
      // Hide on desktop for no-js case.
      display: 'none'
    }
  }
}, { name: 'Nav' })

export default Nav
