import React from 'react'
import { ReactComponent as LogoSvg } from './logo.svg'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import useScrollingLogo from './hooks/useScrollingLogo'
import useResizingLogo, { getBreakpointTranslateAndScale, maximumSize } from './hooks/useResizingLogo'
import theme, { expandAbsolutely, srOnly } from '../../styles/theme'
import composeRefs from '../../helpers/composeRefs'
import Link from '../Link'

const Logo = ({ className }) => {
  const classes = useStyles()
  const ref = composeRefs(useScrollingLogo(), useResizingLogo())
  return (
    <Link className={cn(classes.link, className)} to='/' ref={ref}>
      <LogoSvg className={classes.logo} />
      <span className={classes.srOnly}>Home</span>
    </Link>
  )
}

const calculateTransform = breakpoint => {
  const { x, y, scale } = getBreakpointTranslateAndScale(breakpoint)
  return `translate3d(${x}px, ${y}px, 0) scale3d(${scale}, ${scale}, 1)`
}

const useStyles = createUseStyles({
  srOnly,
  link: {
    display: 'block',
    boxShadow: 'none',
    // set the natural size to the biggest it will ever be, then scale it down
    width: maximumSize,
    height: maximumSize,
    transform: calculateTransform(),
    [theme.breakpoints.up('md')]: {
      transform: calculateTransform('md')
    }
  },
  logo: {
    ...expandAbsolutely,
    fill: 'currentColor'
  }
})

export default Logo
