import inDOM from 'dom-helpers/canUseDOM'
import { scrollBehavior } from 'redux-first-router'
import { BEGIN_ROUTE_EXIT_TRANSITION, ROUTE_EXIT_TRANSITION_ENDED } from '../actions'

export default store => next => action => {
  if (inDOM) {
    if (action.type === BEGIN_ROUTE_EXIT_TRANSITION) {
      scrollBehavior().startIgnoringScrollEvents()
    } else if (action.type === ROUTE_EXIT_TRANSITION_ENDED) {
      scrollBehavior().stopIgnoringScrollEvents()
    }
  }
  return next(action)
}
