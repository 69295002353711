import React from 'react'
import Link from '../Link'

const Role = ({ className, role, employer }) => (
  <h5 className={className}>
    {role}
    {employer && ', '}
    {employer && <Link link={employer.link}>{employer.shortTitle || employer.title}</Link>}
  </h5>
)

export default Role
