import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import first from 'lodash/first'
import HeroTitle from './HeroTitle'
import round from '../../helpers/round'
import ResponsiveImage from '../ResponsiveImage'
import theme, { expandAbsolutely, srOnly } from '../../styles/theme'
import Link from '../Link'
import { resolveInternalLinkUrl } from '../../helpers/resolveLink'
import usePortfolioItem from './hooks/usePortfolioItem'
import AwardBadges from './Work/AwardBadges'

const PortfolioItem = ({ className, index, alignment, listView, selected, refs }) => {
  const classes = useStyles({ alignment })
  const item = usePortfolioItem(index) || {}
  const { images, title, type, awards } = item
  const image = first(images)
  const url = resolveInternalLinkUrl(item)

  const {
    containerRef,
    titleRef,
    titleEtchRef,
    titleTypeRef,
    titleTypeEtchRef,
    awardsRef
  } = refs

  return (
    <div className={cn(className, classes.item)} ref={containerRef}>
      <div className={classes.heroWrapper}>
        <div className={classes.heroContainer}>
          <ResponsiveImage
            className={classes.hero}
            {...image}
          >
            <Link to={url} className={classes.link}>
              <span className={classes.srOnly}>{title} project details</span>
            </Link>
          </ResponsiveImage>
        </div>
        <AwardBadges className={classes.awards} awards={awards} ref={awardsRef} />
      </div>
      <HeroTitle
        className={classes.title}
        title={title}
        type={type}
        alignment={alignment}
        index={index}
        ref={titleRef}
        etchRef={titleEtchRef}
        typeRef={titleTypeRef}
        typeEtchRef={titleTypeEtchRef}
      />
      <div className={classes.aspectPlaceholder} />
    </div>
  )
}

const aspect = 4 / 3
const leftOrRightProp = (alignment, value) => ({
  [alignment === 'left' ? 'left' : 'right']: value
})
const fixedWidth = (ratio, padding) => `calc(${ratio * 100}% - ${padding * 2 * ratio}px)`
const fixedLeftOrRight = (alignment, ratio, padding) => leftOrRightProp(alignment, `calc(${ratio * 100}% + ${(1 - ratio / 2) * padding}px)`)
const fixedTop = (ratio, padding) => `calc(50vh - (${100 * (ratio / 2) / aspect}vw - ${padding * ratio / aspect}px))`
const heroWidthRatio = 0.8
const heroHorzOffsetRatio = 0.05
const animatedHeroHorzOffsetRatio = 0.08
const widescreenHeroWidthRatio = 0.62
const widescreenHeroHorzOffsetRatio = 0.18
const widescreenTitleHorzOffsetRatio = 0.11
const huge = 1960
const fixedStyles = (alignment, widthRatio, horzOffsetRatio) => ({
  top: fixedTop(widthRatio, theme.padding.body.left),
  ...fixedLeftOrRight(alignment, horzOffsetRatio, theme.padding.body.left),
  width: fixedWidth(widthRatio, theme.padding.body.left),
  [theme.breakpoints.up('md')]: {
    top: fixedTop(widthRatio, theme.padding.md.body.left),
    ...fixedLeftOrRight(alignment, horzOffsetRatio, theme.padding.md.body.left),
    width: fixedWidth(widthRatio, theme.padding.md.body.left)
  },
  [theme.breakpoints.up('xxl')]: {
    top: `calc(50vh - ${theme.containerMaxWidth * (widthRatio / 2) / aspect}px)`,
    ...leftOrRightProp(alignment, fixedWidth(0.5, (0.5 - horzOffsetRatio) * theme.containerMaxWidth)),
    width: widthRatio * theme.containerMaxWidth
  }
})

const useStyles = createUseStyles({
  srOnly,
  item: {
    display: 'block',
    position: 'relative',
    padding: 0,
    border: 0,
    background: 'transparent',
    '&:hover': {
      background: 'transparent'
    },
    '.js &': {
      position: 'static',
      // This is to hide the items until we've calculated their initial positions. The .js class is added to the
      // documentElement in the <head> before the user gets to see anything at all.
      visibility: 'hidden'
    },
    '.no-js &': {
      visibility: 'visible'
    }
  },
  hero: {
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    willChange: 'opacity,filter'
  },
  // Note: these dynamic rules are currently causing incorrect warnings on SSR:
  // [JSS] Rule is not linked. Missing sheet option "link: true".
  heroWrapper: ({ alignment }) => ({
    extend: 'aspect',
    position: 'absolute',
    top: `${(1 - heroWidthRatio) * 50}%`,
    width: `${heroWidthRatio * 100}%`,
    maxWidth: theme.containerMaxWidth * heroWidthRatio,
    transform: 'translateZ(-100px)', // ensure that 3d-transformed items are below normal z-index-stacked items (Safari)
    willChange: 'transform',
    ...leftOrRightProp(alignment, `${heroHorzOffsetRatio * 100}%`),
    '.js &': {
      position: 'fixed',
      ...fixedStyles(alignment, heroWidthRatio, animatedHeroHorzOffsetRatio),
      // Actual Macbook Pro 15” size is 1440 × 798
      // This minimum aspect ratio translates to 1440 × 1056
      '@media (min-aspect-ratio: 4/3)': {
        ...fixedStyles(alignment, widescreenHeroWidthRatio, widescreenHeroHorzOffsetRatio),
        [theme.breakpoints.up(huge)]: {
          // Beyond this size, it's quite alright to go back to the same hero sizes as normal because the space at the
          // top and bottom, in absolute units, is now big enough to feel right.
          ...fixedStyles(alignment, heroWidthRatio, animatedHeroHorzOffsetRatio)[theme.breakpoints.up('xxl')]
        }
      }
    }
  }),
  heroContainer: {
    ...expandAbsolutely,
    overflow: 'hidden',
    willChange: 'transform'
  },
  title: ({ alignment }) => ({
    position: 'absolute',
    top: '50%',
    width: '75%',
    maxWidth: 400,
    ...leftOrRightProp(alignment, 0),
    '.js &': {
      position: 'fixed',
      top: '50vh',
      ...leftOrRightProp(alignment, theme.padding.body.left),
      width: fixedWidth(0.75, theme.padding.body.left),
      [theme.breakpoints.up('md')]: {
        ...leftOrRightProp(alignment, `calc(${theme.padding.md.body.left}px)`)
      },
      [theme.breakpoints.up('xxl')]: {
        ...leftOrRightProp(alignment, fixedWidth(0.5, 0.5 * theme.containerMaxWidth))
      },
      '@media (min-aspect-ratio: 4/3)': {
        ...fixedLeftOrRight(alignment, widescreenTitleHorzOffsetRatio, theme.padding.body.left),
        [theme.breakpoints.up('md')]: {
          ...fixedLeftOrRight(alignment, widescreenTitleHorzOffsetRatio, theme.padding.md.body.left)
        },
        [theme.breakpoints.up('xxl')]: {
          ...leftOrRightProp(alignment, fixedWidth(0.5, (0.5 - widescreenTitleHorzOffsetRatio) * theme.containerMaxWidth))
        },
        [theme.breakpoints.up(huge)]: {
          ...leftOrRightProp(alignment, fixedWidth(0.5, 0.5 * theme.containerMaxWidth))
        }
      }
    }
  }),
  link: {
    ...expandAbsolutely,
    display: 'block',
    boxShadow: 'none'
  },
  awards: ({ alignment }) => ({
    position: 'absolute',
    ...leftOrRightProp(alignment, '100%'),
    top: '15%',
    fontSize: 25,
    marginLeft: '0.25em',
    '.js &': {
      visibility: 'hidden'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 40
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 60
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 80
    }
  }),
  aspect: {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      paddingTop: `${round(100 / aspect)}%`
    }
  },
  aspectPlaceholder: {
    extend: 'aspect',
    pointerEvents: 'none'
  }
}, { name: 'PortfolioItem' })

export default PortfolioItem
