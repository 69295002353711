import get from 'lodash/get'
import assign from 'lodash/assign'
import pick from 'lodash/pick'
import compact from 'lodash/compact'
import { createSelector } from 'reselect'
import { getContent, getPageNotFoundContent } from './content'
import { getCurrentPageContent } from './routes'

export const getSiteMetaContent = state => get(getContent(state), ['meta'])
export const getCurrentPageMetaContent = createSelector(
  getSiteMetaContent,
  getCurrentPageContent,
  getPageNotFoundContent,
  (siteMeta, page, notFound) => {
    page = page || notFound
    const pageSeo = page.seo
    const pageMeta = pick(page, ['title', 'description'])
    if (typeof pageMeta.description !== 'string') {
      delete pageMeta.description
    }
    const meta = assign({}, siteMeta, pageMeta, pageSeo || {})
    if (page._type === 'work') {
      if (!get(pageSeo, ['pageSeo.headTitle'])) {
        meta.headTitle = page.title + siteMeta.workTitleSuffix
      }
      if (meta.title === page.title) {
        meta.title = compact([page.title, page.type]).join(' - ')
      }
    }
    return meta
  }
)
export const getSiteName = state => get(getSiteMetaContent(state), ['siteName'])
export const getMetaTitle = state => get(getCurrentPageMetaContent(state), ['title'])
export const getPageHeadTitle = state => get(getCurrentPageMetaContent(state), ['headTitle'])
export const getMetaDescription = state => get(getCurrentPageMetaContent(state), ['description'])
export const getMetaImage = state => get(getCurrentPageMetaContent(state), ['image'])
export const getMetaCanonicalUrl = state => get(getCurrentPageMetaContent(state), ['canonicalUrl'])
export const getH1Title = state => (
  get(getCurrentPageMetaContent(state), ['titleH1']) ||
  get(getCurrentPageMetaContent(state), ['title'])
)
export const getH2Title = state => get(getCurrentPageMetaContent(state), ['titleH2'])
export const getSummaryParagraph = state => get(getCurrentPageMetaContent(state), ['summary'])
export const getLocationDescription = state => get(getSiteMetaContent(state), ['location', 'description'])
export const getGeoLatLng = state => get(getSiteMetaContent(state), ['location', 'geo'])
export const getGeoPositionString = state => {
  const geo = getGeoLatLng(state)
  if (geo) {
    return `${geo.lat};${geo.lng}`
  }
}
export const getGeoIcbmString = state => {
  const geo = getGeoLatLng(state)
  if (geo) {
    return `${geo.lat}, ${geo.lng}`
  }
}
export const getGeoRegion = state => get(getSiteMetaContent(state), ['location', 'geo', 'region'])
export const getGeoPlacename = state => get(getSiteMetaContent(state), ['location', 'geo', 'placename'])
export const getTwitterCardType = state => get(getSiteMetaContent(state), ['twitterCardType'])
