import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import NavLink from './NavLink'
import {
  getCurrentRouteParentSlug,
  getCurrentRouteSlug,
  getPageShortTitlesBySlug,
  getPageTitlesBySlug
} from '../../selectors'
import { slugRouteActionCreatorFactory } from '../../actions'

const NavRouteLink = ({ slug, ...rest }) => {
  const activeSlug = useSelector(getCurrentRouteSlug)
  const activeParentSlug = useSelector(getCurrentRouteParentSlug)
  const titlesBySlug = useSelector(getPageTitlesBySlug)
  const shortTitlesBySlug = useSelector(getPageShortTitlesBySlug)
  const title = titlesBySlug[slug]
  const seoTitle = shortTitlesBySlug[slug]
  const slugRouteActionCreator = useSelector(slugRouteActionCreatorFactory)
  const to = useMemo(() => slugRouteActionCreator(slug), [slug, slugRouteActionCreator])
  return (
    <NavLink to={to} active={slug === activeSlug || slug === activeParentSlug} seoTitle={seoTitle} {...rest}>
      {title}
    </NavLink>
  )
}

export default NavRouteLink
