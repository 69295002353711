import { history, scrollBehavior } from 'redux-first-router'
import inDOM from 'dom-helpers/canUseDOM'
import last from 'lodash/last'
import { calculateHeroItemIndex } from '../middlewares/view/portfolioScroll'

export default function getSavedHeroItemIndex (prevSelectedIndex) {
  const location = history().location
  if (inDOM) {
    const storage = scrollBehavior()._stateStorage
    const savedPageYOffset = last(storage.read(location))
    const savedHeroItemIndex = calculateHeroItemIndex(savedPageYOffset)
    if (savedHeroItemIndex !== undefined && Math.abs(savedHeroItemIndex - prevSelectedIndex) < 1) {
      return savedHeroItemIndex
    }
    return prevSelectedIndex
  }
}
