import inDOM from 'dom-helpers/canUseDOM'
import rejectedPromiseMiddleware from './rejectedPromise'
import promiseMiddleware from 'redux-promise-middleware'
import archivedMiddleware from './archived'
import layoutMiddleware from './layout'
import viewMiddleware from './view'
import transitionsMiddleware from './transitions'
import ignoreScrollDuringTransitionMiddleware from './ignoreScrollDuringTransition'

const middlewares = [
  rejectedPromiseMiddleware,
  promiseMiddleware,
  archivedMiddleware,
  layoutMiddleware,
  viewMiddleware,
  transitionsMiddleware,
  ignoreScrollDuringTransitionMiddleware
]

if (module.hot && process.env.NODE_ENV === 'development') {
  if (inDOM) {
    const hmr = require('./hmr').default
    middlewares.splice(2, 0, hmr)
  }
}

export default middlewares
