import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import identity from 'lodash/identity'
import cloneDeep from 'lodash/cloneDeep'
import { createSelector } from 'reselect'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'

export const isBusy = state => !!state.content.busy
export const getContent = state => state.content.content
export const getContentError = state => state.content.error
export const isContentReadyOrError = state => !isBusy(state) && !!(getContent(state) || getContentError(state))

export const getClientsMap = state => get(getContent(state), ['clientsBySlug']) || {}
export const getClientLookup = createSelector(
  getClientsMap,
  clientsMap => slug => ({ slug, ...clientsMap[slug] })
)
export const getSkillsMap = state => get(getContent(state), ['skillsBySlug'])
export const getSkillLookup = createSelector(
  getSkillsMap,
  skillsMap => slug => skillsMap[slug]
)

export const getPortfolioContent = state => get(getContent(state), ['portfolio'])
export const getCvContent = state => get(getContent(state), ['cv'])
export const getContactContent = state => get(getContent(state), ['contact'])

export const getPortfolioSlug = state => get(getPortfolioContent(state), ['slug'])
export const getPortfolioTitle = state => get(getPortfolioContent(state), ['title'])

function sanitizePortfolioItem (item, getClient, getSkill) {
  const result = cloneDeep(item)
  if (result.role && result.role.clients) {
    result.role.clients = result.role.clients.map(({ client, ...rest }) => ({ ...getClient(client), ...rest }))
  }
  if (result.technology) {
    result.technology = result.technology.map(slug => getSkill(slug))
  }
  return result
}

export const getPortfolioItems = createSelector(
  getPortfolioContent,
  getClientLookup,
  getSkillLookup,
  (content, getClient, getSkill) => (
    get(content, ['work']) || []
  ).map(item => sanitizePortfolioItem(item, getClient, getSkill))
)

export const getPortfolioItemsBySlug = createSelector(
  getPortfolioItems,
  items => keyBy(items, 'slug')
)

export const getCvSlug = state => get(getCvContent(state), ['slug'])
export const getCvTitle = state => get(getCvContent(state), ['title'])
export const getCvShortTitle = state => get(getCvContent(state), ['shortTitle'])

function sanitizeCvExperienceItem (item, getClient) {
  const result = cloneDeep(item)
  if (result.employer) {
    result.employer = getClient(result.employer)
  }
  if (result.clients) {
    result.clients = result.clients.map(({ client, ...rest }) => ({ ...getClient(client), ...rest }))
  }
  return result
}

export const getCvExperienceSlug = state => get(getCvContent(state), ['experience', 'slug'])
export const getCvExperienceTitle = state => get(getCvContent(state), ['experience', 'title'])
export const getCvExperienceItems = createSelector(
  getCvContent,
  getClientLookup,
  (content, getRawClient) => {
    const rawItems = get(content, ['experience', 'items']) || []
    const getClient = slug => {
      const client = getRawClient(slug)
      if (client) {
        return {
          ...client,
          clientWasAlsoEmployer: !!rawItems.find(({ employer }) => employer === slug)
        }
      }
    }
    return rawItems.map(item => sanitizeCvExperienceItem(item, getClient))
  }
)
export const getCvEducationSlug = state => get(getCvContent(state), ['education', 'slug'])
export const getCvEducationTitle = state => get(getCvContent(state), ['education', 'title'])
export const getCvEducationItems = state => get(getCvContent(state), ['education', 'items']) || []
export const getCvDownloadLink = state => get(getCvContent(state), ['download', 'link'])

const sanitizeSkillsMatrixItem = ({ skill: skillSlug, ...rest }, getSkill) => ({
  slug: skillSlug,
  ...getSkill(skillSlug),
  ...rest
})

const sanitizeSkillsMatrixCategory = ({ skills, ...rest }, getSkill) => ({
  ...rest,
  skills: skills.map(item => sanitizeSkillsMatrixItem(item, getSkill))
})

export const getCvSkillsMatrixContent = state => get(getContent(state), ['skillsMatrix'])
export const getCvSkillsMatrixSlug = state => get(getCvSkillsMatrixContent(state), ['slug'])
export const getCvSkillsMatrixTitle = state => get(getCvSkillsMatrixContent(state), ['title'])
export const getCvSkillsMatrixShortTitle = state => get(getCvSkillsMatrixContent(state), ['shortTitle'])

export const getCvSkillsMatrixCategories = createSelector(
  getCvSkillsMatrixContent,
  getSkillLookup,
  (content, getSkill) => (
    get(content, ['categories']) || []
  ).map(category => sanitizeSkillsMatrixCategory(category, getSkill))
)

export const getContactSlug = state => get(getContactContent(state), ['slug'])
export const getContactPathname = state => resolveInternalLinkUrl(getContactContent(state))
export const getContactTitle = state => get(getContactContent(state), ['title'])

export const getArchivedLinks = state => get(getContent(state), ['archived'])
export const getArchivedLinksLookup = state => keyBy(getArchivedLinks(state), identity)

export const getPageNotFoundContent = state => get(getContent(state), ['notFound'])
export const getPageNotFoundSlug = state => get(getPageNotFoundContent(state), ['slug'])
export const getPageNotFoundTitle = state => get(getPageNotFoundContent(state), ['title'])
export const getPageNotFoundCopy = state => get(getPageNotFoundContent(state), ['copy'])
