import React from 'react'
import { createUseStyles } from 'react-jss'
import Link from './Link'
import { useSelector } from 'react-redux'
import { getContactPathname } from '../selectors'

const Archived = ({ url }) => {
  const classes = useStyles()
  const contactUrl = useSelector(getContactPathname)
  return (
    <>
      <h3>Site offline</h3>
      <p>
        Unfortunately <em className={classes.url}>{url}</em> is no longer online.
        Feel free to <Link to={contactUrl}>contact</Link> Pete to request a private demonstration.
      </p>
    </>
  )
}

const useStyles = createUseStyles({
  url: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word'
  }
}, { name: 'Archived' })

export default Archived
