import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import map from 'lodash/map'
import zipObject from 'lodash/zipObject'
import range from 'lodash/range'
import theme, { expandAbsolutely, srOnly } from '../../styles/theme'
import { toggleMenuCreator } from '../../actions'
import { isMenuOpen, isPreHydrate } from '../../selectors'

const closeLineKeys = map(range(4), i => `closeLine${i + 1}`)
const lineKeys = [
  'topLine',
  'middleLine',
  'bottomLine',
  ...closeLineKeys
]

const Hamburger = React.forwardRef(({ className }, ref) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const onClick = useCallback(() => {
    dispatch(toggleMenuCreator())
  }, [dispatch])
  const open = useSelector(isMenuOpen)
  const preHydrate = useSelector(isPreHydrate)

  return (
    <button
      className={cn(classes.hamburger, open && classes.open, className)}
      type='button'
      onClick={onClick}
      ref={ref}
    >
      {map(lineKeys, (key, i) => (
        <span key={key} className={classes[key]}>
          {i === 0 && <span className={classes.srOnly}>Menu</span>}
        </span>
      ))}
      {preHydrate && <label className={classes.preHydrateToggle} htmlFor='hamburger-toggle' />}
    </button>
  )
})

const padding = theme.padding.nav.left
const width = 28
const navHeight = 45
const height = 17
const lineHeight = 2

const preHydrateOpenSelector = '#hamburger-toggle:checked ~ $hamburger > &'
const useStyles = createUseStyles({
  srOnly,
  hamburger: {
    position: 'relative',
    appearance: 'none',
    width: padding * 2 + width,
    height: padding * 2 + navHeight,
    color: 'inherit',
    backgroundColor: 'transparent',
    border: 0,
    padding: 0,
    margin: 0,
    outline: 0,
    cursor: 'pointer'
  },
  open: {}, // defined for use in nested selectors
  line: {
    position: 'absolute',
    display: 'block',
    right: padding,
    width,
    height: lineHeight,
    backgroundColor: 'currentColor'
  },
  openLine: {
    extend: 'line',
    '.no-js &': {
      transition: 'opacity 0.4s ease-out 0.4s'
    },
    [`.no-js ${preHydrateOpenSelector}`]: {
      opacity: 0,
      transition: 'opacity 0.2s ease-out 0s'
    }
  },
  topLine: {
    extend: 'openLine',
    top: padding + (navHeight - height) / 2
  },
  middleLine: {
    extend: 'openLine',
    top: padding + (navHeight - lineHeight) / 2
  },
  bottomLine: {
    extend: 'openLine',
    top: padding + (navHeight + height - lineHeight) / 2,
    width: width * (3 / 4)
  },
  // The close icon is made up of four half-length lines drawn outwards from the center, which are initially scaled to
  // have zero length but can then be transitioned outwards from the centre
  closeLine: {
    extend: 'line',
    left: padding + (width - lineHeight) / 2,
    right: 'auto',
    top: padding + (navHeight - lineHeight) / 2,
    width: (width + lineHeight) / 2,
    transformOrigin: `${lineHeight / 2}px ${lineHeight / 2}px`,
    transition: 'transform 0.1s linear '
  },
  ...zipObject(closeLineKeys, map(closeLineKeys, (key, i) => {
    const rotation = `rotate(${-135 + i * 90}deg)`
    return {
      extend: 'closeLine',
      transform: `${rotation} scale(0, 1)`,
      transitionDelay: `${0.05 * i}s`,
      [`$open > &, ${preHydrateOpenSelector}`]: {
        transitionDelay: `${0.4 + 0.1 * i}s`,
        transform: `${rotation} scale(1, 1)`
      }
    }
  })),
  preHydrateToggle: {
    ...expandAbsolutely,
    cursor: 'pointer'
  }
})

export default Hamburger
