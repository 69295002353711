import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getCvExperienceSlug, getCvExperienceTitle, getCvExperienceItems } from '../../selectors'
import Section from './Section'
import Role from './Role'
import Clients from './Clients'
import Callouts from './Callouts'
import Highlights from './Highlights'
import Client from './Client'

const Experience = ({ className }) => {
  const slug = useSelector(getCvExperienceSlug)
  const title = useSelector(getCvExperienceTitle)
  const items = useSelector(getCvExperienceItems)
  const classes = useStyles()
  const calloutsRendered = {}
  const maybeRenderEmployerCallout = employer => {
    if (employer && !calloutsRendered[employer.slug]) {
      calloutsRendered[employer.slug] = true
      return (
        <Callouts className={classes.employerCallouts}>
          <Client key={employer.slug} client={employer} />
        </Callouts>
      )
    }
  }
  return (
    <Section className={className} id={slug} title={title}>
      {items.map(({ from, to, role, clients, employer, highlights }, i) => (
        <section key={i} className={classes.item}>
          <h4>{from} – {to || 'Current'}</h4>
          <Role className={classes.role} role={role} employer={employer} />
          {maybeRenderEmployerCallout(employer)}
          {highlights && <Highlights highlights={highlights} />}
          {clients && <Clients clients={clients} />}
        </section>
      ))}
    </Section>
  )
}

const useStyles = createUseStyles({
  item: {
    marginBottom: 50
  },
  highlights: {
    marginBottom: 50
  },
  employerCallouts: {
    // align with the role heading
    marginTop: -20 * (1.3 + 1.2) // lineHeight + marginBottom
  }
}, { name: 'Experience' })

export default Experience
