/* global fetch:false */
import { getContentApiUrl, getCurrentPageType, isContentReadyOrError, isPageNotFound } from './selectors'
import {
  contentActionCreator,
  ROUTE_NOT_FOUND,
  ROUTE_CONTENT,
  createRouteAction
} from './actions'

async function fetchContent (apiUrl, slug) {
  const url = [apiUrl, slug].join('/')

  const response = await fetch(url, {
    headers: {
      Accept: 'application/json'
    }
  })

  const data = await response.json()

  if (!response.ok) {
    const error = new Error((data.error && data.error.message) || 'Failed to fetch content')
    error.status = response.status
    throw error
  }

  return data
}

async function thunk (dispatch, getState) {
  if (!isContentReadyOrError(getState())) {
    const apiUrl = getContentApiUrl(getState())
    await dispatch(contentActionCreator(fetchContent(apiUrl, '')))
  }
  if (getCurrentPageType(getState()) === 'notFound' && !isPageNotFound(getState())) {
    dispatch(createRouteAction(ROUTE_NOT_FOUND))
  }
}

const routesMap = {
  [ROUTE_CONTENT]: {
    path: '*',
    toPath: segment => segment,
    thunk
  },
  [ROUTE_NOT_FOUND]: {
    path: '/not-found',
    thunk
  }
}

export default routesMap
