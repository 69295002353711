import React from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import {
  getH1Title,
  getH2Title,
  getLocationDescription,
  getSummaryParagraph,
  isPortfolioRoute,
  isPreHydrate
} from '../selectors'
import theme, { srOnly } from '../styles/theme'
import useSiteTitleTransition from './hooks/useSiteTitleTransition'
import RichText from './RichText'

const SiteTitle = ({ className }) => {
  const classes = useStyles()
  const title = useSelector(getH1Title)
  const subtitle = useSelector(getH2Title)
  const summary = useSelector(getSummaryParagraph)
  const location = useSelector(getLocationDescription)
  const preHydrate = useSelector(isPreHydrate)
  const ref = useSiteTitleTransition(classes.summary)
  const isVisibleOnRoute = useSelector(isPortfolioRoute)
  return (
    <>
      <header className={cn(className, classes.siteTitle, isVisibleOnRoute && classes.visibleOnRoute)}>
        <h1 className={classes.title}>{title}</h1>
        {subtitle && <h2 className={classes.subtitle}>{subtitle}</h2>}
        <RichText className={cn(classes.summary, classes.staticSummary)} content={summary} />
        <p className={classes.location}>{location}</p>
      </header>
      {!preHydrate && ReactDOM.createPortal(
        // If we animate the summary inside the <header>, Google’s crawler picks up the split <divs> and renders the
        // summary like “I. m. a. k. e. b. e. a...” which looks stupid! So, animate a separate copy at the bottom of
        // the body instead.
        <div className={cn(classes.siteTitle, classes.animatedSiteTitle)} ref={ref} aria-hidden='true'>
          <RichText className={classes.summary} content={summary} />
        </div>,
        document.body
      )}
    </>
  )
}

const rightMd = 150
const rightXl = 250

const useStyles = createUseStyles({
  siteTitle: {
    textAlign: 'right',
    position: 'fixed',
    width: 260,
    zIndex: 1,
    pointerEvents: 'none',
    userSelect: 'none',
    cursor: 'default',
    right: 20,
    top: 90,
    fontSize: 12,
    visibility: 'hidden', // will be animated in
    [theme.breakpoints.up('md')]: {
      fontSize: 'inherit',
      // position so text runs bottom-to-top, left-to-right
      top: 'auto',
      right: rightMd,
      bottom: 'calc(100% - 90px)',
      transformOrigin: '100% 100%',
      transform: 'rotate3d(0, 0, 1, -90deg)'
    },
    [theme.breakpoints.up('xl')]: {
      right: rightXl
    },
    [theme.breakpoints.up('xxl')]: {
      right: `calc(50vw - ${theme.containerMaxWidth / 2 - (rightXl - theme.padding.md.body.left)}px)`
    }
  },
  visibleOnRoute: {
    '.no-js &': {
      // Make visible in the no-js case without any transitions.
      visibility: 'visible',
      [theme.breakpoints.down('xl')]: {
        position: 'relative',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        zIndex: 'auto',
        margin: [0, 0, 40, 'auto'],
        [theme.breakpoints.up('md')]: {
          transform: 'none',
          marginRight: -theme.padding.md.body.left + theme.padding.md.nav.left
        }
      },
      [theme.breakpoints.up('xxl')]: {
        right: theme.padding.md.nav.left
      }
    }
  },
  srOnly,
  title: {
    composes: '$srOnly'
  },
  subtitle: {
    composes: '$srOnly'
  },
  location: {
    composes: '$srOnly'
  },
  summary: {
    '& > p': {
      marginBottom: '1.2em',
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  staticSummary: {
    '.js &': {
      ...srOnly
    }
  }
}, { name: 'SiteTitle' })

export default SiteTitle
