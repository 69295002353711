import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import gsap from 'gsap'
import {
  addBackgroundColorListener, addColorListener,
  removeBackgroundColorListener, removeColorListener
} from '../middlewares/view/colors'
import theme from '../styles/theme'

const useHoverColorScheme = () => {
  const listenersRef = useRef()
  return useCallback(element => {
    if (element) {
      const backgroundColor = backgroundColor => {
        gsap.set(element, { color: backgroundColor })
      }
      const color = color => {
        gsap.set(element, { backgroundColor: color })
      }

      addBackgroundColorListener(backgroundColor)
      addColorListener(color)
      listenersRef.current = { backgroundColor, color }
    } else if (listenersRef.current) {
      const { backgroundColor, color } = listenersRef.current
      removeBackgroundColorListener(backgroundColor)
      removeColorListener(color)
      listenersRef.current = null
    }
  }, [])
}

const Button = ({ className, children, ...rest }) => {
  const classes = useStyles()
  const hoverRef = useHoverColorScheme()
  const Hover = useCallback(({ children }) => (
    <span className={classes.hoverWrapper} ref={hoverRef}>
      <span className={classes.hover}>
        {children}
      </span>
    </span>
  ), [hoverRef])
  // If there is a single non-string child then we will attempt to style that as a button
  // (the assumption is that it should be a <Link />).
  // Any other children will be rendered inside a <button />.
  if (React.Children.count(children) === 1 && typeof children !== 'string') {
    return React.cloneElement(
      children, {
        className: cn(className, classes.button, children.props.className)
      },
      children.props.children,
      // For our hover effect we need a duplicate of the children
      <Hover>{children.props.children}</Hover>
    )
  }
  return (
    <button type='button' className={cn(className, classes.button)} {...rest}>
      {children}
      <Hover>{children}</Hover>
    </button>
  )
}

const verticalFactor = 0
const horizontalFactor = 15
const transition = 'transform 0.2s linear'

const useStyles = createUseStyles({
  button: {
    appearance: 'none',
    backgroundColor: 'transparent',
    border: '1px solid',
    display: 'inline-block',
    lineHeight: 1.4,
    padding: [12, 30],
    textDecoration: 'none',
    textTransform: 'uppercase',
    position: 'relative',
    textAlign: 'center',
    overflow: 'hidden',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)', // fixes overflow:hidden on Safari
    boxShadow: 'none',
    '&:hover': {
      '& > $hoverWrapper': {
        transform: 'skew(-40deg) translateX(0%)',
        '& > $hover': {
          transform: 'translateX(0%) skew(40deg)'
        }
      }
    }
  },
  hoverWrapper: {
    display: 'block',
    position: 'absolute',
    top: `${-verticalFactor}%`,
    bottom: `${-verticalFactor}%`,
    left: `${-horizontalFactor}%`,
    right: `${-horizontalFactor}%`,
    pointerEvents: 'none',
    transform: 'skew(-40deg) translateX(100%)',
    overflow: 'hidden',
    backgroundColor: theme.colors.text,
    color: theme.colors.primary,
    transition
  },
  hover: {
    extend: 'button',
    position: 'absolute',
    top: `${100 * (verticalFactor / (2 * verticalFactor + 100))}%`,
    bottom: `${100 * (verticalFactor / (2 * verticalFactor + 100))}%`,
    left: `${100 * (horizontalFactor / (2 * horizontalFactor + 100))}%`,
    right: `${100 * (horizontalFactor / (2 * horizontalFactor + 100))}%`,
    border: 0,
    display: 'block',
    transform: `translateX(${-horizontalFactor * 2 - 100}%) skew(40deg)`,
    transition
  }
}, { name: 'Button' })

export default Button
