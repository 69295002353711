import React from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Items from './Items'
import { isMenuOpen } from '../../selectors'
import theme from '../../styles/theme'
import { getBottomStatusBarHeight } from '../../helpers/getBottomStatusBarHeight'

const Menu = React.forwardRef(({ className, children }, ref) => {
  const classes = useStyles()
  const open = useSelector(isMenuOpen)
  return (
    <div className={cn(className, classes.container, open && classes.open)} ref={ref}>
      <Items classes={classes}>
        {children}
      </Items>
    </div>
  )
})

const dividerSize = 14
const padding = 30
const firstItemPadding = padding * 2.5

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    height: '100vh',
    textTransform: 'uppercase',
    fontSize: 24,
    opacity: 0,
    pointerEvents: 'none',
    '.no-js &': {
      backgroundColor: theme.colors.primary,
      transition: 'opacity 0.2s ease-in-out'
    },
    '#hamburger-toggle:checked ~ &': {
      opacity: 1,
      pointerEvents: 'auto'
    }
  },
  open: {
    // will be made opacity:1 by parent, via useMenuFromHamburgerTransition()
    pointerEvents: 'auto'
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'block',
    listStyleType: 'none',
    padding: 0,
    margin: [padding, 0],
    position: 'relative',
    '&:first-child': {
      marginTop: firstItemPadding - getBottomStatusBarHeight() / 2
    },
    '@media (max-height: 460px)': {
      margin: [padding * 0.7, 0],
      '&:first-child': {
        marginTop: firstItemPadding * 2 - getBottomStatusBarHeight() / 2,
        '@media (orientation: landscape)': {
          marginTop: padding * 0.7
        }
      }
    },
    '@media (max-height: 420px) and (orientation: portrait)': {
      margin: [padding * 0.5, 0],
      '&:first-child': {
        marginTop: firstItemPadding * 1.75 - getBottomStatusBarHeight() / 2,
        '@media (orientation: landscape)': {
          marginTop: padding * 0.5
        }
      }
    }
  },
  divider: {
    borderTop: '1px solid currentColor',
    position: 'absolute',
    left: '50%',
    top: '100%',
    display: 'inline-block',
    width: dividerSize,
    marginLeft: -dividerSize / 2,
    marginTop: padding - 2
  },
  activeLink: {
    boxShadow: 'none',
    '&::before, &::after': {
      display: 'inline-block',
      fontSize: '0.8em',
      lineHeight: 1.4 / 0.8,
      verticalAlign: 'top'
    },
    '&::before': {
      content: '"<"',
      marginRight: 10
    },
    '&::after': {
      content: '">"',
      marginLeft: 10
    }
  }
}, { name: 'Menu' })

export default Menu
