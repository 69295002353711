import { useEffect, useRef } from 'react'

/**
 * Returns the value passed to the hook last time it was called with a different argument. The return value is stable
 * across re-renders - that is, the “previous” value changes only when the argument changes.
 * @param value
 * @returns the previous value passed to the hook that differs from the current value
 */
export function usePreviousValue (value) {
  const ref = useRef()
  const prevRef = useRef()
  if (value !== ref.current) {
    prevRef.current = ref.current
    ref.current = value
  }
  return prevRef.current
}

/**
 * Returns the argument passed to the hook on the previous render. This means that the returned value will be identical
 * to the current argument on subsequent renders, unless the argument changes again.
 * @param value
 * @returns the value passed on the previous render
 */
export default function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
