import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import SectionTitle from './SectionTitle'
import theme from '../styles/theme'
import { useSelector } from 'react-redux'
import { getPageNotFoundCopy, getPageNotFoundSlug, getPageNotFoundTitle } from '../selectors'
import RichText from './RichText'

const NotFound = ({ className }) => {
  const classes = useStyles()
  const slug = useSelector(getPageNotFoundSlug)
  const title = useSelector(getPageNotFoundTitle)
  const copy = useSelector(getPageNotFoundCopy)
  return (
    <article className={cn(className, classes.container)}>
      <header>
        <SectionTitle id={slug} srOnly={false}>{title}</SectionTitle>
      </header>
      <RichText className={classes.copy} content={copy} />
    </article>
  )
}

const useStyles = createUseStyles({
  bottomMargin: {
    marginBottom: 50
  },
  container: {
    extend: [theme.textMaxWidthStyles],
    composes: '$bottomMargin'
  },
  copy: {
    composes: '$bottomMargin'
  }
}, { name: 'NotFound' })

export default NotFound
