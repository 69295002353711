/* global window */
import inDOM from 'dom-helpers/canUseDOM'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Link from './Link'
import SectionTitle from './SectionTitle'
import Definitions, { Definition } from './Definitions'
import { getContactContent, getContactSlug, getContactTitle } from '../selectors'
import theme from '../styles/theme'
import RichText from './RichText'
import ResponsiveImage from './ResponsiveImage'

const Method = ({ type, link }) => (
  <Definition
    term={type} description={
      <Link link={link} />
    }
  />
)

const Mobile = ({ mobile }) => {
  const mobilePlain = mobile.replace(/[^\d]/, '')
  const mobileLink = {
    url: `tel:${mobilePlain.replace(/^0/, '+61')}`,
    text: mobile.replace(/(\d{4})(\d{3})(\d{3})/, '$1.$2.$3')
  }
  return <Method type='Phone' link={mobileLink} />
}

const Email = ({ email }) => {
  const [deobfuscateEmail, setDeobfuscateEmail] = useState(false)

  let link = { text: email }
  if (deobfuscateEmail) {
    const deobfuscatedEmail = email
      .replace('(at)', '@')
      .replace(/\([^)]+domain[^)]+\)/, window.location.hostname)
      .replace(/\s/g, '')
    link = {
      url: `mailto:${encodeURIComponent(deobfuscatedEmail)}`,
      text: deobfuscatedEmail
    }
  }

  if (inDOM) {
    useEffect(() => {
      setDeobfuscateEmail(true)
    }, [])
  }

  return <Method type='Email' link={link} />
}

const Github = ({ github }) => (
  <Method
    type='Github' link={{
      url: `https://github.com/${github}`,
      text: `@${github}`,
      target: '_blank'
    }}
  />
)

const Contact = ({ className }) => {
  const { copy, mobile, email, github, profilePicture } = useSelector(getContactContent)
  const slug = useSelector(getContactSlug)
  const title = useSelector(getContactTitle)
  const classes = useContactStyles()
  return (
    <article className={cn(className, classes.container)}>
      <header>
        <SectionTitle id={slug} srOnly={false}>{title}</SectionTitle>
      </header>
      <RichText className={classes.copy} content={copy} />
      <div className={classes.pictureAndContacts}>
        <div className={classes.pictureWrapper}>
          <ResponsiveImage className={classes.picture} {...profilePicture} />
        </div>
        <Definitions className={classes.contacts}>
          <Mobile mobile={mobile} />
          <Email email={email} />
          <Github github={github} />
        </Definitions>
      </div>
    </article>
  )
}

const pictureSize = 120

const useContactStyles = createUseStyles({
  bottomMargin: {
    marginBottom: 50
  },
  container: {
    extend: [theme.textMaxWidthStyles],
    composes: '$bottomMargin',
    overflow: 'hidden',
    '.js &': {
      visibility: 'hidden'
    }
  },
  copy: {
    composes: '$bottomMargin'
  },
  pictureAndContacts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: 50
    }
  },
  pictureWrapper: {
    flex: `0 0 ${pictureSize}px`,
    width: pictureSize,
    height: pictureSize,
    borderRadius: '50%',
    overflow: 'hidden',
    border: '1px solid',
    marginBottom: 20,
    order: 1,
    alignSelf: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      order: 0,
      alignSelf: 'center',
      marginBottom: 0,
      marginRight: 40
    }
  },
  picture: {
    width: pictureSize - 8,
    height: pictureSize - 8,
    margin: 3,
    borderRadius: '50%',
    overflow: 'hidden'
  },
  contacts: {
    flex: '1 1 auto',
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  }
}, { name: 'Contact' })

export default Contact
