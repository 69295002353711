import inDOM from 'dom-helpers/canUseDOM'
import closest from 'dom-helpers/closest'
import { getArchivedLinksLookup } from '../selectors'
import { showArchivedLinkModal } from '../actions'

export default store => {
  if (inDOM) {
    document.body.addEventListener('click', e => {
      const a = closest(e.target, 'a')
      if (a) {
        const archivedLinks = getArchivedLinksLookup(store.getState())
        if (archivedLinks[a.href]) {
          e.preventDefault()
          store.dispatch(showArchivedLinkModal(a.href))
        }
      }
    })
  }
  return next => action => next(action)
}
