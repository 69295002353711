import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Callouts from './Callouts'
import Client from './Client'
import Highlights from './Highlights'
import theme from '../../styles/theme'

const Clients = ({ className, clients }) => {
  const classes = useStyles()
  let hasRenderedClientCallouts = false
  const maybeRenderClientCallouts = client => {
    if (!hasRenderedClientCallouts && client.title && !client.clientWasAlsoEmployer) {
      hasRenderedClientCallouts = true
      return (
        <Callouts className={classes.callouts}>
          {clients.slice(0, 3).map((client, i) => (
            client.title && !client.clientWasAlsoEmployer
              ? <Client key={client.slug} client={client} />
              : null
          ))}
        </Callouts>
      )
    }
  }
  return (
    <div className={cn(className, classes.container)}>
      {clients.map((client, i) => {
        const { work, title } = client
        return (
          <React.Fragment key={i}>
            {maybeRenderClientCallouts(client)}
            <h5 className={classes.name}>
              {title}
            </h5>
            {work && <Highlights highlights={work} />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative'
  },
  name: {
  },
  highlights: {
    marginBottom: 50
  },
  callouts: {
    [theme.breakpoints.up('xl')]: {
      // the font size bumps up at this breakpoint, causing these to run into each other - so move them up a bit
      top: 'auto',
      bottom: 0,
      '& > *:last-child': {
        marginBottom: 0
      }
    }
  }
}, { name: 'Clients' })

export default Clients
