import React, { useMemo, forwardRef } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import merge from 'lodash/merge'
import Link from './Link'
import { resolveLink } from '../helpers/resolveLink'

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <Link to={mark.href} target={mark.open_in_new_window ? '_blank' : undefined}>{children}</Link>
    ),
    internal_link: ({ mark, children }) => (
      <Link link={resolveLink(mark)}>{children}</Link>
    )
  }
}

const RichText = forwardRef(({ content, serializers: extraSerializers, ...rest }, ref) => {
  const allSerializers = useMemo(() => {
    if (extraSerializers) {
      return merge({}, serializers, extraSerializers)
    }
    return serializers
  }, [extraSerializers])
  return (
    <BlockContent
      blocks={content}
      serializers={allSerializers}
      {...rest}
      ref={ref}
    />
  )
})

export default RichText
