import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../styles/theme'
import Items from './Items'

const Horizontal = ({ className, rightAlignLastItem = false, ...rest }) => {
  const allClasses = useStyles()
  const classes = useMemo(() => {
    if (rightAlignLastItem) {
      return {
        ...allClasses,
        item: cn(allClasses.item, allClasses.itemWithRightAlignLastItem)
      }
    } else {
      return allClasses
    }
  }, [allClasses, rightAlignLastItem])
  return (
    <Items className={className} classes={classes} {...rest} />
  )
}

const paddingXs = '0.8em'
const paddingSm = '1.4em'

const useStyles = createUseStyles({
  items: {
    display: 'flex',
    textTransform: 'uppercase',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'inline-block',
    listStyleType: 'none',
    padding: 0,
    margin: [0, paddingXs, 0, 0],
    whiteSpace: 'nowrap',
    '&:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      margin: [0, paddingSm, 0, 0]
    }
  },
  itemWithRightAlignLastItem: {
    '&:first-child': {
      marginLeft: 'auto'
    },
    '&:nth-last-child(2)': {
      marginRight: 'auto',
      '& $divider': {
        display: 'none'
      }
    },
    '&:last-child': {
      position: 'absolute',
      marginRight: 0,
      right: 40,
      top: 25
    }
  },
  divider: {
    borderTop: '1px solid',
    position: 'relative',
    left: 'auto',
    top: 'auto',
    display: 'inline-block',
    width: '1em',
    verticalAlign: 'bottom',
    height: '0.7em', // half of the line height
    marginLeft: paddingXs,
    [theme.breakpoints.up('sm')]: {
      marginLeft: paddingSm
    },
    [theme.breakpoints.up('md')]: {
      width: '3em'
    }
  }
}, { name: 'Horizontal' })

export default Horizontal
