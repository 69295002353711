import { CONTENT_FULFILLED, CONTENT_PENDING, CONTENT_REJECTED } from '../actions'

const initialState = {
}

const content = (state = initialState, action) => {
  switch (action.type) {
    case CONTENT_PENDING:
      return {
        ...state,
        busy: true
      }
    case CONTENT_FULFILLED:
      return {
        ...state,
        busy: false,
        error: undefined,
        content: action.payload
      }
    case CONTENT_REJECTED:
      return {
        ...state,
        busy: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default content
