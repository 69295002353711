import React from 'react'

export default function mapStringChildren (children, fn) {
  return React.Children.map(children, child => {
    if (typeof child === 'string') {
      return fn(child)
    }

    if (!React.isValidElement(child)) {
      return child
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: mapStringChildren(child.props.children, fn)
      })
    }

    return child
  })
}
