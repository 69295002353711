import React, { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Transition, SwitchTransition } from 'react-transition-group'
import { updateScroll } from 'redux-first-router'
import gsap from 'gsap'
import Portfolio from './Portfolio'
import Cv from './Cv'
import Contact from './Contact'
import NotFound from './NotFound'
import { getCurrentPageType, getCurrentPortfolioItemIndex } from '../selectors'
import {
  beginRouteExitTransitionCreator,
  routeExitTransitionEndedCreator
} from '../actions'

const duration = 0.7

const Content = ({ className }) => {
  const pageType = useSelector(getCurrentPageType)
  const currentPortfolioItemIndex = useSelector(getCurrentPortfolioItemIndex)
  const dispatch = useDispatch()
  const tweenRef = useRef()

  const onEnter = useCallback(async (node) => {
    tweenRef.current = gsap.fromTo(node, { autoAlpha: 0 }, { duration, autoAlpha: 1, ease: 'none' }).pause()
    await updateScroll()
    tweenRef.current.play()
  }, [tweenRef])

  const onExit = useCallback((node) => {
    const onComplete = () => {
      dispatch(routeExitTransitionEndedCreator())
    }
    tweenRef.current = gsap.to(node, { duration, autoAlpha: 0, ease: 'none', onComplete })
    dispatch(beginRouteExitTransitionCreator())
  }, [tweenRef, dispatch])

  const addEndListener = useCallback((node, done) => {
    if (tweenRef.current) {
      const onComplete = tweenRef.current.eventCallback('onComplete')
      tweenRef.current.eventCallback('onComplete', () => {
        if (onComplete) {
          onComplete()
        }
        done()
      })
    }
  }, [tweenRef, dispatch])

  // Special case: because the Portfolio list view needs to transition seamlessly to a selected work item, the
  // selected item itself needs to be rendered by the Portfolio component. So alias 'work' as 'portfolio' here.
  const type = pageType === 'work' ? 'portfolio' : pageType
  let Component
  const props = { className }
  switch (type) {
    case 'cv':
      Component = Cv
      break
    case 'contact':
      Component = Contact
      break
    case 'portfolio':
      Component = Portfolio
      props.selectedIndex = currentPortfolioItemIndex
      break
    default:
      Component = NotFound
  }

  return (
    <SwitchTransition>
      <Transition
        key={type}
        addEndListener={addEndListener}
        onEnter={onEnter}
        onExit={onExit}
        appear
      >
        <Component {...props} />
      </Transition>
    </SwitchTransition>
  )
}

export default Content
