/* global ResizeObserver */
import inDOM from 'dom-helpers/canUseDOM'

let el
let width = 375
let height = 667

if (inDOM) {
  // On mobile browsers, window.innerHeight is scaled according to pinch zoom; and on iOS the value
  // changes depending on whether the bottom toolbar is visible or not.
  // A reliable way to get the pixel height of the maximum available space (regardless whether the
  // bottom toolbar is visible or not) is to use the CSS value `100vh`
  el = document.createElement('div')
  el.style.width = '100vw'
  el.style.height = '100vh'
  el.style.position = 'absolute'
  el.style.left = '-100vw'
  el.style.top = '-100vh'
  el.style.visibility = 'hidden'
  const ro = new ResizeObserver(() => {
    width = document.documentElement.clientWidth
    height = document.documentElement.clientHeight
    if (Math.min(width, height) < 480) {
      width = el.offsetWidth
      height = el.offsetHeight
    }
  })
  document.body.appendChild(el)
  ro.observe(el)
}

export default function getViewportSize () {
  return { width, height }
}
