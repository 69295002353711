import { getOptions, pathToAction, selectLocationState, NOT_FOUND } from 'redux-first-router'
import { getPathNamesBySlug } from '../selectors'

export const ROUTE_CONTENT = 'ROUTE_CONTENT'
export const ROUTE_NOT_FOUND = NOT_FOUND

export const slugRouteActionCreatorFactory = state => {
  const { routesMap } = selectLocationState(state)
  const { querySerializer } = getOptions()
  const pathNamesBySlug = getPathNamesBySlug(state)
  return slug => pathToAction(pathNamesBySlug[slug] || slug, routesMap, querySerializer)
}

export const isRouteActionType = actionType => {
  switch (actionType) {
    case ROUTE_CONTENT:
    case ROUTE_NOT_FOUND:
      return true
    default:
      return false
  }
}

export const createRouteAction = route => ({
  type: route
})
