import { useMemo } from 'react'
import mapValues from 'lodash/mapValues'
import cn from 'classnames'

export default function mergeClassNames (classes, otherClasses) {
  return useMemo(
    () => mapValues(classes, (value, key) => cn(value, otherClasses[key])),
    [classes, otherClasses]
  )
}
