import * as breakpoints from './breakpoints'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import round from '../helpers/round'
import * as fonts from './fonts'

export function remCalc (px) {
  if (isArray(px)) {
    return px.map(remCalc)
  } else {
    return `${round(px / 16)}rem`
  }
}

export const expandAbsolutely = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
}

export const clearFix = {
  '&::before,&:after': {
    content: '" "',
    display: 'table'
  },
  '&:after': {
    clear: 'both'
  }
}

export const srOnly = {
  border: '0 !important',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  clipPath: 'inset(50%) !important',
  height: '1px !important',
  margin: '-1px !important',
  overflow: 'hidden !important',
  padding: '0 !important',
  position: 'absolute !important',
  width: '1px !important',
  whiteSpace: 'nowrap !important'
}

const white = '#fff'
const black = '#000'
const colors = {
  white,
  black,
  texts: [
    black,
    white,
    white,
    black,
    white
  ],
  primaries: [
    '#fff4c9',
    '#ec796e',
    '#15403d',
    '#dfab5f',
    '#466584'
  ]
}
colors.primary = colors.primaries[0]
colors.text = colors.texts[0]
colors.schemes = map(colors.primaries, (backgroundColor, i) => ({
  backgroundColor,
  color: colors.texts[i]
}))

const padding = {
  body: {
    left: 20,
    top: 130
  },
  nav: {
    left: 20,
    top: 20
  },
  md: {
    body: {
      left: 80,
      top: 130
    },
    nav: {
      left: 40,
      top: 25
    }
  }
}

const minSpaceAlongsideTextLg = 314
const minSpaceAlongsideTextXl = 374
export const textMaxWidth = 680

export const anchor = {
  '&::before': {
    display: 'block',
    content: '" "',
    marginTop: -padding.body.top,
    height: padding.body.top,
    visibility: 'hidden',
    pointerEvents: 'none'
  }
}

export default {
  breakpoints,
  colors,
  fonts,
  zIndex: {
    nav: 10,
    modal: 90,
    work: 1,
    scrollHint: 2
  },
  containerMaxWidth: breakpoints.values.xxl - padding.md.body.left * 2,
  textMaxWidthStyles: {
    maxWidth: textMaxWidth,
    [breakpoints.up('lg')]: {
      maxWidth: `calc(100vw - ${padding.md.body.left * 2 + minSpaceAlongsideTextLg}px)`
    },
    [breakpoints.up('xl')]: {
      maxWidth: `calc(100vw - ${padding.md.body.left * 2 + minSpaceAlongsideTextXl}px)`
    },
    [breakpoints.up(textMaxWidth + padding.md.body.left * 2 + minSpaceAlongsideTextXl)]: {
      maxWidth: textMaxWidth
    }
  },
  padding
}
