import React from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { getCvSkillsMatrixCategories, getCvSkillsMatrixSlug, getCvSkillsMatrixTitle } from '../../selectors'
import Section from './Section'

const SkillsMatrix = ({ className }) => {
  const categories = useSelector(getCvSkillsMatrixCategories)
  const slug = useSelector(getCvSkillsMatrixSlug)
  const title = useSelector(getCvSkillsMatrixTitle)
  const classes = useStyles()
  return (
    <Section className={className} id={slug} title={title}>
      <table className={classes.table}>
        <thead className={classes.header}>
          <tr>
            <th />
            <th>Yrs Exp.</th>
            <th>Last Used</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(({ title: categoryTitle, skills }) => (
            <React.Fragment key={categoryTitle}>
              <tr>
                <th colSpan='3' className={classes.category}>{categoryTitle}</th>
              </tr>
              {skills.map(({ slug, title, years, until }) => (
                <tr key={slug}>
                  <td className={classes.technology}>
                    {title.replace('/', '/\u200B') /* zero-width space to allow line breaks at '/' characters */}
                  </td>
                  <td>{years < 1 ? '<1' : years}</td>
                  <td>{until || 'Current'}</td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Section>
  )
}

const useStyles = createUseStyles({
  table: {
    textAlign: 'left',
    width: 'calc(100% + 2em)' // horz margin is -1em to counteract border-spacing
  },
  header: {
    '& th': {
      fontSize: '0.8em'
    }
  },
  category: {
    padding: ['0.6em', 0, 0],
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '1em',
      marginLeft: '1.3em',
      borderBottom: '1px solid'
    },
    'tbody > tr:first-child > &': {
      paddingTop: 0
    }
  },
  technology: {
    paddingLeft: 20
  }
}, { name: 'SkillsMatrix' })

export default SkillsMatrix
