import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { textMaxWidth } from '../../styles/theme'

const Callouts = ({ className, children }) => {
  const classes = useStyles()
  return (
    <div className={cn(className, classes.callouts)}>
      {children}
    </div>
  )
}

const marginLeft = 50
const marginLeftLg = 120
const marginBreakpointSm = theme.padding.md.body.left * 2 + textMaxWidth + marginLeft + 300
const marginBreakpointLg = theme.padding.md.body.left * 2 + textMaxWidth + marginLeftLg + 300

const useStyles = createUseStyles({
  callouts: {
    position: 'absolute',
    left: '100%',
    marginLeft,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    },
    [theme.breakpoints.up(marginBreakpointSm)]: {
      marginLeft: `calc(100vw - ${marginBreakpointSm - marginLeft}px)`
    },
    [theme.breakpoints.up(marginBreakpointLg)]: {
      marginLeft: marginLeftLg
    }
  }
}, { name: 'Callouts' })

export default Callouts
