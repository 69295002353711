import { isDesktopSelector, isMobileSelector } from './layout'

export const getPortfolioHeroIndex = state => state.view.portfolioHeroIndex
export const isMenuOpen = state => isMobileSelector(state) && state.view.menuOpen
export const isScrolledDown = state => state.view.scrolledDown
export const getCurrentLocationHash = state => state.view.locationHash
export const isRouteExitTransitionInProgress = state => state.view.routeExiting
export const isScrollHintScrolledDown = state => state.view.scrollHint.scrolledDown
export const isScrollHintRequired = state => state.view.scrollHint.required
export const hasScrollHintDelayElapsed = state => state.view.scrollHint.delayElapsed
export const isScrollHintSeen = state => state.view.scrollHint.seen
export const isScrollHintVisible = state => (
  isScrollHintRequired(state) &&
  hasScrollHintDelayElapsed(state) &&
  !isScrollHintScrolledDown(state) &&
  !isScrollHintSeen(state)
)
export const getFocusThreshold = state => isDesktopSelector(state) ? 0.9 : 0.95
