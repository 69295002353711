import inDOM from 'dom-helpers/canUseDOM'
import scrollTop from 'dom-helpers/scrollTop'
import position from 'dom-helpers/position'
import { createPath } from 'rudy-history'
import throttle from 'lodash/throttle'
import {
  createLocationHashChangedAction,
  isRouteActionType,
  REHYDRATED
} from '../../actions'
import { getAnchorSlugsByPageSlug, getCurrentLocationHash, getCurrentRouteSlug } from '../../selectors'

let pauseScrollHashChanges = false

export default store => {
  if (inDOM) {
    const dispatchHash = () => {
      const hash = window.location.hash
      if (hash !== getCurrentLocationHash(store.getState())) {
        pauseScrollHashChanges = true
        setTimeout(() => {
          pauseScrollHashChanges = false
        }, 1500)
        store.dispatch(createLocationHashChangedAction(hash))
      }
    }

    window.addEventListener('hashchange', () => {
      dispatchHash()
      // Because we have manual scroll restoration, we need to scroll manually if the hashchange was due to a history
      // pop. It's not possible to tell whether it was a history pop or not from here though so just always scroll
      // manually.
      const hash = window.location.hash
      if (hash) {
        const el = document.getElementById(hash.substr(1))
        if (el) {
          scrollTop(window, position(el, document.documentElement).top)
        }
      } else {
        // when the hash changes to no hash, scroll back to the top
        scrollTop(window, 0)
      }
    })

    window.addEventListener('scroll', throttle(() => {
      if (pauseScrollHashChanges) {
        return
      }

      const pageAnchors = getAnchorSlugsByPageSlug(store.getState())
      const hashes = pageAnchors[getCurrentRouteSlug(store.getState())]
      const threshold = window.innerHeight * 0.4
      if (hashes && hashes.length) {
        let lastHashPassed = hashes[0]
        hashes.forEach(id => {
          const el = document.getElementById(id)
          if (el) {
            const { top } = el.getBoundingClientRect()
            if (top < threshold) {
              lastHashPassed = id
            }
          }
        })
        const hash = `#${lastHashPassed}`
        if (hash !== getCurrentLocationHash(store.getState())) {
          window.history.replaceState(window.history.state, null, createPath({
            ...window.location,
            hash
          }))
          store.dispatch(createLocationHashChangedAction(hash))
        }
      }
    }, 10, { leading: false }), { passive: true })

    return next => action => {
      const ret = next(action)
      if (action.type === REHYDRATED || isRouteActionType(action.type)) {
        dispatchHash()
      }

      return ret
    }
  }

  // During SSR, do nothing
  return next => action => next(action)
}
