import inDOM from 'dom-helpers/canUseDOM'

const needsPromise = typeof Promise === 'undefined'

const needsResponsiveImage = inDOM && (() => {
  const img = document.createElement('img')
  const supportSrcset = ('sizes' in img) && ('srcset' in img)
  return !supportSrcset || !window.HTMLPictureElement || !!document.msElementsFromPoint
})()

const needsObjectFit = inDOM && (() => {
  const style = document.createElement('a').style
  const fitSupport = 'objectFit' in style
  const positionSupport = fitSupport && 'objectPosition' in style
  return !fitSupport || !positionSupport
})()

const needsResizeObserver = inDOM && !window.ResizeObserver

export default function initPolyfills () {
  const promises = []

  if (needsPromise) {
    // can't even use import() without polyfilling Promise....
    require('promise/lib/rejection-tracking').enable()
    window.Promise = require('promise/lib/es6-extensions.js')

    promises.push(import(/* webpackChunkName: 'polyfills-ie11' */ './ie11'))
  }

  if (needsObjectFit) {
    promises.push(import(/* webpackChunkName: 'polyfills-ls.object-fit' */ 'lazysizes/plugins/object-fit/ls.object-fit'))
  }

  if (needsResponsiveImage) {
    promises.push(import(/* webpackChunkName: 'polyfills-ls.respimg' */ 'lazysizes/plugins/respimg/ls.respimg'))
  }

  if (needsResizeObserver) {
    promises.push((async () => {
      const { default: ResizeObserver } = await import(/* webpackChunkName: 'polyfills-resize-observer' */ 'resize-observer-polyfill')
      window.ResizeObserver = ResizeObserver
    })())
  }

  return Promise.all(promises)
}
