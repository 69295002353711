import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import isElement from 'lodash/isElement'
import each from 'lodash/each'
import isArray from 'lodash/isArray'
import isObjectLike from 'lodash/isObjectLike'
import set from 'lodash/set'

const eachDeep = (collection, iterator, basePath = []) => each(
  collection,
  (value, key) => {
    const path = [...basePath, key]
    if (iterator(value, path) !== false && isObjectLike(value)) {
      eachDeep(value, iterator, path)
    }
  }
)

export default function useMountUnmountActions (refs, mountedActionCreator, unmountedActionCreator) {
  const dispatch = useDispatch()
  useEffect(() => {
    let els = isArray(refs) ? [] : {}
    eachDeep(refs, (value, path) => {
      if (isElement(value)) {
        if (path.length > 1) {
          const elPath = path.slice(0, path.length - 2)
          const [, key, currentKey] = path
          if (currentKey === 'current') {
            elPath.push(key.replace(/Ref$/, 'El'))
            set(els, elPath, value)
          }
        } else {
          if (path[0] === 'current') {
            els = value
          } else {
            set(els, path, value)
          }
        }
        return false
      }
    })
    dispatch(mountedActionCreator(els))
    return () => {
      dispatch(unmountedActionCreator(els))
    }
  }, [refs])
}
