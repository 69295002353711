import React from 'react'
import { createUseStyles } from 'react-jss'
import { Helmet } from 'react-helmet-async'
import Nav from './Nav'
import SiteTitle from './SiteTitle'
import Content from './Content'
import Modal from './Modal'
import theme from '../styles/theme'
import { useSelector } from 'react-redux'
import {
  getCanonicalUrlBase,
  getCurrentRoutePath,
  getGeoIcbmString,
  getGeoPlacename,
  getGeoPositionString,
  getGeoRegion,
  getMetaCanonicalUrl,
  getMetaDescription,
  getMetaImage,
  getMetaTitle,
  getPageHeadTitle,
  getSiteName,
  getTwitterCardType
} from '../selectors'
import get from 'lodash/get'
import last from 'lodash/last'

const useStyles = createUseStyles({
  '@global': {
    '@font-face': theme.fonts.faces,
    '*, *::before, *::after': {
      boxSizing: 'border-box'
    },
    html: {
      lineHeight: 1.4,
      scrollBehavior: 'smooth'
    },
    body: {
      margin: 0,
      padding: [theme.padding.body.top, theme.padding.body.left, theme.padding.body.left],
      backgroundColor: theme.colors.primary,
      color: theme.colors.text,
      fontFamily: theme.fonts.body,
      fontWeight: theme.fonts.bodyFontWeight,
      '-webkitFontSmoothing': 'antialiased',
      '-mozOsxFontSmoothing': 'grayscale',
      fontSize: 15,
      [theme.breakpoints.up('md')]: {
        padding: [theme.padding.md.body.top, theme.padding.md.body.left, theme.padding.md.body.left]
      }
    },
    'h1,h2,h3,h4,h5': {
      fontFamily: theme.fonts.headings,
      fontWeight: theme.fonts.headingsFontWeight,
      lineHeight: 1.3,
      margin: [0, 0, '0.6em']
    },
    h1: { // h1 is for SEO only so h2 or h3 is the big heading people will generally see
      fontSize: 46,
      [theme.breakpoints.up('md')]: {
        fontSize: 56
      }
    },
    'h2,h3': {
      fontSize: 38,
      [theme.breakpoints.up('md')]: {
        fontSize: 48
      }
    },
    h4: {
      fontSize: 20,
      marginBottom: '1.5em',
      [theme.breakpoints.up('md')]: {
        fontSize: 25
      }
    },
    h5: {
      fontSize: 18,
      marginBottom: '1.2em',
      [theme.breakpoints.up('md')]: {
        fontSize: 20
      }
    },
    a: {
      color: 'inherit',
      position: 'relative',
      textDecoration: 'none',
      boxShadow: '0 1px 0 0 currentColor',
      '&:hover': {
        color: 'inherit'
      }
    },
    p: {
      margin: [0, 0, '0.75em']
    },
    ul: {
      margin: 0,
      padding: 0
    },
    li: {
      listStyleType: 'disc',
      listStylePosition: 'inside',
      margin: [0, 0, '0.5em'],
      padding: 0,
      position: 'relative'
    },
    table: {
      borderCollapse: 'separate',
      borderSpacing: '1em 0.6em',
      margin: [0, '-1em']
    },
    th: {
      fontWeight: 'bold',
      textTransform: 'uppercase'
    }
  },
  container: {
    maxWidth: theme.containerMaxWidth,
    margin: [0, 'auto']
  }
}, { name: 'App' })

const App = () => {
  const classes = useStyles()
  const canonicalUrlBase = useSelector(getCanonicalUrlBase)
  const currentRoutePath = useSelector(getCurrentRoutePath)
  const canonicalUrl = useSelector(getMetaCanonicalUrl) || `${canonicalUrlBase}${currentRoutePath}`
  const headTitle = useSelector(getPageHeadTitle)
  const siteName = useSelector(getSiteName)
  const metaTitle = useSelector(getMetaTitle)
  const metaDescription = useSelector(getMetaDescription)
  const ogImage = useSelector(getMetaImage)
  const ogImageAlt = get(ogImage, ['alt'])
  const ogImageAsset = last(get(ogImage, ['sizes']))
  const twitterCardType = useSelector(getTwitterCardType)
  const geoRegion = useSelector(getGeoRegion)
  const geoPlacename = useSelector(getGeoPlacename)
  const geoPositionString = useSelector(getGeoPositionString)
  const geoIcbmString = useSelector(getGeoIcbmString)
  return (
    <>
      <Helmet>
        <title>{headTitle}</title>
        {metaTitle && <meta name='title' content={metaTitle} />}
        {metaDescription && <meta name='description' content={metaDescription} />}
        {siteName && <meta property='og:site_name' content={siteName} />}
        {metaTitle && <meta property='og:title' content={metaTitle} />}
        {canonicalUrl && <meta property='og:url' content={canonicalUrl} />}
        <meta property='og:type' content='website' />
        {ogImageAsset && ([
          // Fragments don’t seem to work here - use an array instead.
          <meta property='og:image' content={ogImageAsset.url} key='og:image' />,
          <meta property='og:image:width' content={ogImageAsset.width} key='og:image:width' />,
          <meta property='og:image:height' content={ogImageAsset.height} key='og:image:height' />,
          ogImageAlt && <meta property='og:image:alt' content={ogImageAlt} key='og:image:alt' />
        ])}
        {metaDescription && <meta property='og:description' content={metaDescription} />}
        {twitterCardType && <meta name='twitter:card' content={twitterCardType} />}
        {siteName && <meta name='twitter:site' content={siteName} />}
        {metaTitle && <meta name='twitter:title' content={metaTitle} />}
        {metaDescription && <meta name='twitter:description' content={metaDescription} />}
        {ogImageAsset && <meta name='twitter:image' content={ogImageAsset.url} />}
        {geoRegion && <meta name='geo.region' content={geoRegion} />}
        {geoPlacename && <meta name='geo.placename' content={geoPlacename} />}
        {geoPositionString && <meta name='geo.position' content={geoPositionString} />}
        {geoIcbmString && <meta name='ICBM' content={geoIcbmString} />}
        {canonicalUrl && <link rel='canonical' href={canonicalUrl} />}
      </Helmet>
      <div className={classes.container}>
        <Nav />
        <main>
          <SiteTitle />
          <Content />
        </main>
        <Modal />
      </div>
    </>
  )
}

export default App
