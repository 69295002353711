import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Experience from './Experience'
import Education from './Education'
import SkillsMatrix from './SkillsMatrix'
import Download from './Download'
import SectionTitle from '../SectionTitle'
import theme from '../../styles/theme'
import { useSelector } from 'react-redux'
import { getCvSlug, getCvTitle } from '../../selectors'

const Cv = ({ className }) => {
  const classes = useStyles()
  const slug = useSelector(getCvSlug)
  const title = useSelector(getCvTitle)
  return (
    <article className={cn(className, classes.container)}>
      <div className={classes.content}>
        <SectionTitle id={slug}>{title}</SectionTitle>
        <Download className={classes.topDownload} />
        <Experience />
        <Education />
        <SkillsMatrix />
        <Download />
      </div>
    </article>
  )
}

const useStyles = createUseStyles({
  container: {
    // opacity animation in page transitions doesn’t work without this
    overflow: 'hidden',
    '.js &': {
      visibility: 'hidden'
    }
  },
  content: {
    extend: [theme.textMaxWidthStyles],
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  topDownload: {
    float: 'right',
    zIndex: 1,
    margin: [3, 0, 50, 20],
    [theme.breakpoints.up('md')]: {
      marginTop: 10
    }
  }
}, { name: 'Cv' })

export default Cv
