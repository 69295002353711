import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import Technology from './Technology'
import RichText from '../../RichText'
import theme from '../../../styles/theme'

const Details = ({ className, technology, description }) => {
  const classes = useStyles()
  return (
    <div className={cn(className, classes.details)}>
      <Technology className={classes.technology} technology={technology} />
      <RichText className={classes.description} content={description} renderContainerOnSingleChild />
    </div>
  )
}

const useStyles = createUseStyles({
  details: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'flex-end'
    }
  },
  technology: {
    maxWidth: 480,
    marginBottom: 50,
    [theme.breakpoints.up('lg')]: {
      margin: [100, 80, 0, 0],
      marginRight: 80,
      width: '40%',
      flex: '0 1 35%'
    }
  },
  description: {
    maxWidth: 540,
    marginBottom: 50,
    [theme.breakpoints.up('lg')]: {
      flex: '0 1 50%',
      margin: [100, 0, 0, 'auto'],
      textAlign: 'right'
    }
  }
}, { name: 'WorkDetails' })

export default Details
