import { BREAKPOINT_CHANGED, REHYDRATED } from '../actions'
import { current } from '../styles/breakpoints'
import inDOM from 'dom-helpers/canUseDOM'

const initialState = {
  breakpoint: current(),
  breakpointSet: inDOM
}

const layout = (state = initialState, action) => {
  switch (action.type) {
    case BREAKPOINT_CHANGED:
      return {
        ...state,
        breakpoint: action.payload.breakpoint,
        breakpointSet: true
      }
    case REHYDRATED:
      return {
        ...state,
        breakpointSet: true
      }

    default:
      return state
  }
}

export default layout
