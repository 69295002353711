import inDOM from 'dom-helpers/canUseDOM'

let bottomStatusBarHeight = !inDOM ? 0 : undefined

export function getBottomStatusBarHeight () {
  if (bottomStatusBarHeight === undefined) {
    const el = document.createElement('div')
    el.style.width = '100vw'
    el.style.height = '100vh'
    el.style.position = 'absolute'
    el.style.left = '-100vw'
    el.style.top = '-100vh'
    el.style.visibility = 'hidden'
    document.body.appendChild(el)
    bottomStatusBarHeight = el.offsetHeight - document.documentElement.clientHeight
    document.body.removeChild(el)
  }
  return bottomStatusBarHeight
}
