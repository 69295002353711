import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import { Transition, SwitchTransition } from 'react-transition-group'
import cn from 'classnames'
import theme, { srOnly } from '../../styles/theme'
import Anchors from './Anchors'
import { getCurrentRouteSlug, getCvSlug, isPreHydrate } from '../../selectors'

const duration = 0.7

const Secondary = ({ className }) => {
  const classes = useStyles()
  const preHydrate = useSelector(isPreHydrate)
  const cvSlug = useSelector(getCvSlug)
  const slug = useSelector(getCurrentRouteSlug)
  const needsAnchors = slug === cvSlug

  const tweenRef = useRef()

  const onEnter = useCallback((node) => {
    tweenRef.current = gsap.fromTo(node, { autoAlpha: 0 }, { duration, autoAlpha: 1, ease: 'none', delay: duration })
  }, [tweenRef])

  const onExit = useCallback((node) => {
    tweenRef.current = gsap.to(node, { duration, autoAlpha: 0, ease: 'none' })
  }, [tweenRef])

  const addEndListener = useCallback((node, done) => {
    if (tweenRef.current) {
      tweenRef.current.eventCallback('onComplete', () => {
        done()
      })
    }
  }, [tweenRef])

  const addNoTransitionEndListener = useCallback((node, done) => {
    setTimeout(done, 0)
  }, [])

  return (
    <SwitchTransition>
      <Transition
        key={slug}
        addEndListener={needsAnchors ? addEndListener : addNoTransitionEndListener}
        onEnter={needsAnchors ? onEnter : undefined}
        onExit={needsAnchors ? onExit : undefined}
        appear={!preHydrate}
      >
        {needsAnchors ? (
          <Anchors className={cn(className, classes.anchors)} slug={slug} />
        ) : <div />}
      </Transition>
    </SwitchTransition>
  )
}

const useStyles = createUseStyles({
  anchors: {
    position: 'absolute',
    right: 40,
    top: 137,
    [theme.breakpoints.down('lg')]: srOnly
  }
}, { name: 'Secondary' })

export default Secondary
