import React, { useMemo, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import slice from 'lodash/slice'
import usePortfolioItem from '../hooks/usePortfolioItem'
import theme from '../../../styles/theme'
import useSelectedOrPreviousIndex from '../hooks/useSelectedOrPreviousIndex'
import Header from './Header'
import Info from './Info'
import Details from './Details'
import Images from './Images'
import useMountUnmountActions from '../../hooks/useMountUnmountActions'
import { workMountedCreator, workUnmountedCreator } from '../../../actions'

const Work = ({ className, index }) => {
  const classes = useStyles()
  const selectedIndex = useSelectedOrPreviousIndex(index)
  const item = usePortfolioItem(selectedIndex)
  const { title, type, link, date, role, awards, technology, description, images } = item || {}
  const galleryImages = useMemo(() => slice(images, 1), [images])
  const hasGallery = galleryImages.length > 0
  const ref = useRef()
  useMountUnmountActions(ref, workMountedCreator, workUnmountedCreator)
  return (
    <div className={cn(className, classes.work)} ref={ref}>
      <div className={classes.backgroundImagePlaceholder} />
      {item && (
        <>
          <div className={cn(classes.content, !hasGallery && classes.onlyContent)}>
            <Header number={selectedIndex + 1} title={title} type={type} link={link} />
            <Info className={classes.info} date={date} role={role} awards={awards} />
            <Details className={classes.details} technology={technology} description={description} />
          </div>
          <Images images={galleryImages} />
        </>
      )}
    </div>
  )
}

const padding = {
  h: 40,
  v: 80,
  md: {
    h: 160,
    v: 160
  }
}

padding.xxl = {
  body: {
    h: 40
  },
  v: padding.md.v
}
padding.xxl.h = `calc(50% - ${theme.containerMaxWidth / 2 - padding.xxl.body.h - (padding.md.h - theme.padding.md.body.left)}px)`

const useStyles = createUseStyles({
  work: {
    zIndex: theme.zIndex.work,
    '.js &': {
      height: 0, // initially removed from page flow
      overflow: 'hidden',
      visibility: 'hidden'
    },
    [theme.breakpoints.up('xxl')]: {
      paddingLeft: padding.xxl.body.h,
      paddingRight: padding.xxl.body.h
    }
  },
  backgroundImagePlaceholder: {
    pointerEvents: 'none',
    position: 'fixed',
    left: padding.h,
    right: padding.h,
    top: padding.v,
    height: `calc(100vh - ${padding.v * 2}px)`,
    [theme.breakpoints.up('md')]: {
      left: padding.md.h,
      right: padding.md.h,
      top: padding.md.v,
      height: `calc(100vh - ${padding.md.v * 2}px)`
    },
    [theme.breakpoints.up('xxl')]: {
      left: padding.xxl.h,
      right: padding.xxl.h,
      top: padding.xxl.v,
      height: `calc(100vh - ${padding.xxl.v * 2}px)`
    }
  },
  body: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 13
    }
  },
  info: {
    extend: 'body'
  },
  details: {
    extend: 'body',
    [theme.breakpoints.up('lg')]: {
      marginTop: 'auto'
    }
  },
  onlyContent: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `calc(100vh - ${theme.padding.md.body.top * 2}px)`
    }
  }
}, { name: 'Work' })

export default Work
