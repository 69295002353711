import inDOM from 'dom-helpers/canUseDOM'
import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import PhysicsPropsPlugin from 'gsap/PhysicsPropsPlugin'
import { width } from '../../../styles/breakpoints'
import { isBreakpointSet, isDesktopSelector, isMenuOpen, isPortfolioRoute } from '../../../selectors'
import { setLogoVelocity } from './useScrollingLogo'
import getViewportSize from '../../../helpers/getViewportSize'

gsap.registerPlugin(PhysicsPropsPlugin)

const sizeMobile = 54
const sizeMobileMenuOpen = 200
const sizeDesktop = 68
const sizeDesktopPortfolio = 155
export const maximumSize = Math.max(sizeMobile, sizeMobileMenuOpen, sizeDesktop, sizeDesktopPortfolio)

function getTranslateAndScale (requestedSize, viewportWidth, viewportHeight) {
  const isMaximum = requestedSize === maximumSize
  if (!viewportWidth || !viewportHeight) {
    ({ width: viewportWidth, height: viewportHeight } = getViewportSize())
  }
  const size = inDOM ? Math.min(requestedSize, viewportWidth * 0.54, viewportHeight * 0.4) : requestedSize
  const scale = size / sizeMobileMenuOpen
  let x = (size - maximumSize) / 2
  let y = x
  if (isMaximum) {
    x = -38 / scale
    y = -54 / scale
  }

  return { x, y, scale }
}

export function getBreakpointTranslateAndScale (breakpoint) {
  const viewportWidth = width(breakpoint) || 375
  const isDesktop = viewportWidth >= width('md')
  return getTranslateAndScale(isDesktop ? sizeDesktop : sizeMobile, viewportWidth, viewportWidth)
}

const logoSizeSelector = state => {
  if (isDesktopSelector(state)) {
    if (isPortfolioRoute(state)) {
      return sizeDesktopPortfolio
    }
    return sizeDesktop
  }
  if (isMenuOpen(state)) {
    return sizeMobileMenuOpen
  }
  return sizeMobile
}

export default function useResizingLogo () {
  const ref = useRef()
  const ready = useSelector(isBreakpointSet)
  const size = useSelector(logoSizeSelector)
  const delayRef = useRef(0)

  useEffect(() => {
    if (!ready) {
      return
    }
    const { x, y, scale } = getTranslateAndScale(size)
    const delay = delayRef.current
    gsap.to(ref.current, {
      duration: 0.4,
      x,
      y,
      scale,
      ease: 'sine.in',
      delay
    })

    const logoEl = ref.current.childNodes[0]
    gsap.delayedCall(delay, () => {
      const direction = Math.sign(logoEl.prevVelocity || 1)
      setLogoVelocity(logoEl, 1000 * direction)
    })

    // the initial render should not be delayed, but thereafter we should delay
    delayRef.current = 0.4
  }, [ref, size, ready, delayRef])

  return ref
}
