import React from 'react'
import map from 'lodash/map'
import cn from 'classnames'
import Horizontal from '../Nav/Horizontal'
import NavLink from '../Nav/NavLink'
import { createUseStyles } from 'react-jss'
import theme from '../../styles/theme'
import { useSelector } from 'react-redux'
import { getAnchorLinksByPageSlug, getCurrentLocationHash } from '../../selectors'

const Anchors = ({ className, slug }) => {
  const classes = useStyles()
  const anchorLinksByPageSlug = useSelector(getAnchorLinksByPageSlug)
  const links = anchorLinksByPageSlug[slug]
  const hash = useSelector(getCurrentLocationHash)
  return links ? (
    <Horizontal className={cn(classes.anchors, className)}>
      {map(links, (link, i) => (
        <NavLink key={i} link={link} active={hash ? (hash === link.url) : (i === 0)} />
      ))}
    </Horizontal>
  ) : null
}

const useStyles = createUseStyles({
  anchors: {
    justifyContent: 'flex-end',
    fontSize: 12,
    [theme.breakpoints.up('xxl')]: {
      fontSize: 18
    }
  }
})

export default Anchors
