import get from 'lodash/get'
import compact from 'lodash/compact'

function resolveSlug (document) {
  const slug = get(document, ['slug'], document)
  if (slug !== 'home') { // home resolves to `/`
    return slug
  }
}

function resolveSection (type) {
  if (type === 'work') {
    return type
  }
}

export function resolveInternalLinkUrl ({ _type: type, slug }) {
  const parts = [
    resolveSection(type),
    resolveSlug(slug)
  ]
  return `/${compact(parts).join('/')}`
}

export function resolveInternalLink (reference, text) {
  if (!reference) {
    return reference
  }
  return {
    text: text || reference.title,
    url: resolveInternalLinkUrl(reference)
  }
}

export function resolveLink (link) {
  if (get(link, ['_type']) === 'internalLink' ||
    get(link, ['_type']) === 'internal_link') {
    return resolveInternalLink(link.reference, link.text)
  }
  return link
}
