import React from 'react'
import compact from 'lodash/compact'
import map from 'lodash/map'
import Definitions, { Definition } from '../../Definitions'
import Link from '../../Link'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme, { srOnly } from '../../../styles/theme'
import AwardBadges from './AwardBadges'

const Clients = ({ clients }) => map(clients, (client, i) => (
  <React.Fragment key={`client${i}`}>
    <Link link={client.link}>{client.shortTitle || client.title}</Link>
    {client.date && ` (${client.date})`}
    {i < clients.length - 1 && <br />}
  </React.Fragment>
))

const renderRole = ({ type, clients, role }) => {
  switch (type) {
    case 'freelance':
      return (
        <Definition
          term={`Client${clients.length === 1 ? '' : 's'}`}
          description={<Clients clients={clients} />}
        />
      )
    case 'employee':
      return (
        <>
          <Definition term='Role' description={role} />
          <Definition term='Employer' description={<Clients clients={clients} />} />
        </>
      )
    default:
      return <Definition term='Project' description={role} />
  }
}

const AwardDescription = ({ title, shortTitle, url }) => {
  const classes = useAwardDescriptionStyles()
  return (
    <Link to={url} target='_blank'>
      {shortTitle ? (
        <>
          <span className={classes.awardShortTitle}>
            {shortTitle}
          </span>
          <span className={classes.awardLongTitle}>
            <span className={classes.srOnly}> (</span>
            {title}
            <span className={classes.srOnly}>)</span>
          </span>
        </>
      ) : title}
    </Link>
  )
}

const renderAwardInfo = awards => map(awards, ({ type, ...award }, i) => (
  <Definition
    key={i}
    term={type || 'Award'}
    description={<AwardDescription {...award} />}
  />
))

const Info = ({ className, date, role, awards }) => {
  const classes = useStyles()
  date = date && compact([date.fromYear, date.year]).join('-')
  return (
    <div className={cn(className, classes.container)}>
      <Definitions className={classes.info} transparent>
        {renderRole(role)}
        {date && <Definition term='Year' description={date} />}
        {renderAwardInfo(awards)}
      </Definitions>
      <AwardBadges className={classes.badges} awards={awards} />
    </div>
  )
}

const useAwardDescriptionStyles = createUseStyles({
  srOnly,
  awardShortTitle: {
    [theme.breakpoints.up('sm')]: {
      ...srOnly
    }
  },
  awardLongTitle: {
    [theme.breakpoints.down('xs')]: {
      ...srOnly
    }
  }
}, { name: 'WorkAwardDescription' })

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  info: {
    maxWidth: 480,
    flex: '1 1 auto',
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      marginRight: 30
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 40
    }
  },
  badges: {
    marginLeft: 'auto',
    fontSize: 40,
    [theme.breakpoints.up('md')]: {
      fontSize: 60,
      width: `${2 * 1.25}em`
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 80,
      width: `${3 * 1.25}em`
    }
  }
}, { name: 'WorkInfo' })

export default Info
