import { createSelector } from 'reselect'
import { NOT_FOUND, selectLocationState } from 'redux-first-router'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import compact from 'lodash/compact'
import chunk from 'lodash/chunk'
import fromPairs from 'lodash/fromPairs'
import mapValues from 'lodash/mapValues'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import last from 'lodash/last'
import {
  getContactContent,
  getCvContent,
  getCvEducationSlug,
  getCvEducationTitle,
  getCvExperienceSlug,
  getCvExperienceTitle,
  getCvSkillsMatrixSlug,
  getCvSkillsMatrixTitle,
  getCvSlug,
  getPortfolioContent,
  getPortfolioItems,
  getPortfolioSlug
} from './content'
import { resolveInternalLinkUrl } from '../helpers/resolveLink'

export const getCurrentRoute = state => selectLocationState(state).type
export const getCurrentRoutePath = state => selectLocationState(state).pathname
const getPathComponents = path => compact((path || '').split('/'))
export const getPathRouteSlug = path => last(getPathComponents(path)) || 'home'
export const getCurrentRouteSlug = state => getPathRouteSlug(getCurrentRoutePath(state))
export const getPathSectionSlug = path => {
  const components = getPathComponents(path)
  if (components.length > 1) {
    return components[0]
  }
}
export const getCurrentRouteSectionSlug = state => getPathSectionSlug(getCurrentRoutePath(state))
export const getCurrentRouteParentSlug = state => {
  if (getCurrentRouteSectionSlug(state) === 'work') {
    return 'home'
  }
}

export const getRoutesMap = state => selectLocationState(state).routesMap
export const isPageNotFound = state => getCurrentRoute(state) === NOT_FOUND

export const makeIsPortfolioPath = state => path => getPathRouteSlug(path) === getPortfolioSlug(state)
export const isPortfolioRoute = state => {
  const isPortfolioPath = makeIsPortfolioPath(state)
  return isPortfolioPath(getCurrentRoutePath(state))
}
export const isWorkPath = path => getPathSectionSlug(path) === 'work'
export const isWorkRoute = state => isWorkPath(getCurrentRoutePath(state))

const pagesWithUrls = pages => map(pages, page => ({
  ...page,
  url: resolveInternalLinkUrl(page)
}))

const getTopLevelPages = createSelector(
  getPortfolioContent,
  getCvContent,
  getContactContent,
  (...pages) => pagesWithUrls(pages)
)

const getAllPages = createSelector(
  getTopLevelPages,
  getPortfolioItems,
  (pages, items) => [...pages, ...pagesWithUrls(items)]
)

const getCvAnchorSlugs = createSelector(
  getCvExperienceSlug,
  getCvEducationSlug,
  getCvSkillsMatrixSlug,
  (...slugs) => slugs
)

const getCvAnchorTitles = createSelector(
  getCvExperienceTitle,
  getCvEducationTitle,
  getCvSkillsMatrixTitle,
  (...titles) => titles
)

export const getAnchorSlugsByPageSlug = createSelector(
  getCvSlug,
  getCvAnchorSlugs,
  (...pageSlugAndAnchorSlugs) => fromPairs(chunk(pageSlugAndAnchorSlugs, 2))
)

export const getAnchorLinksByPageSlug = createSelector(
  getCvSlug,
  getCvAnchorSlugs,
  getCvAnchorTitles,
  (...triplets) => fromPairs(
    map(
      chunk(triplets, 3),
      ([pageSlug, slugs, titles]) => [
        pageSlug,
        map(slugs, (slug, i) => ({
          url: `#${slug}`,
          text: titles[i]
        }))
      ]
    )
  )
)

export const getTopLevelPageSlugs = createSelector(
  getTopLevelPages,
  pages => map(pages, 'slug')
)

export const getPageTitlesBySlug = createSelector(
  getAllPages,
  pages => mapValues(keyBy(pages, 'slug'), 'title')
)

export const getPageShortTitlesBySlug = createSelector(
  getAllPages,
  pages => mapValues(keyBy(pages, 'slug'), 'shortTitle')
)

export const getPathNamesBySlug = createSelector(
  getAllPages,
  pages => mapValues(keyBy(pages, 'slug'), 'url')
)

export const getCurrentPageContent = createSelector(
  getCurrentRouteSectionSlug,
  getCurrentRouteSlug,
  getAllPages,
  (sectionSlug, slug, pages) => {
    const toFind = { slug }
    if (sectionSlug) {
      toFind._type = sectionSlug
    }
    return find(pages, toFind)
  }
)

export const getCurrentPageType = createSelector(
  getCurrentPageContent,
  (page) => get(page, ['_type'], 'notFound')
)

export const isPortfolioPageType = pageType => pageType === 'portfolio'
export const isWorkPageType = pageType => pageType === 'work'
export const isPortfolioOrWorkPageType = pageType => isWorkPageType(pageType) || isPortfolioPageType(pageType)
export const isPortfolioOrWork = state => isPortfolioOrWorkPageType(getCurrentPageType(state))

export const getCurrentPortfolioItemIndex = createSelector(
  getCurrentRouteSectionSlug,
  getCurrentRouteSlug,
  getPortfolioItems,
  (sectionSlug, slug, items) => {
    if (sectionSlug !== 'work') {
      return -1
    }
    return findIndex(items, { slug })
  }
)
