import { oneLineTrim } from 'common-tags'

export const body = 'GT America Mono'
export const bodyFontWeight = 'normal'
export const headings = 'GT Cinetype'
export const headingsFontWeight = 'bold'

export const faces = [{
  fontFamily: body,
  src: oneLineTrim`
    url('${require('./GT-America-Mono-Regular.eot').default}?#iefix') format('embedded-opentype'),
    url('${require('./GT-America-Mono-Regular.woff2').default}') format('woff2'),
    url('${require('./GT-America-Mono-Regular.woff').default}') format('woff'),
    url('${require('./GT-America-Mono-Regular.ttf').default}')  format('truetype')`,
  fontWeight: bodyFontWeight,
  fontStyle: 'normal'
}, {
  fontFamily: body,
  src: oneLineTrim`
    url('${require('./GT-America-Mono-Bold.eot').default}?#iefix') format('embedded-opentype'),
    url('${require('./GT-America-Mono-Bold.woff2').default}') format('woff2'),
    url('${require('./GT-America-Mono-Bold.woff').default}') format('woff'),
    url('${require('./GT-America-Mono-Bold.ttf').default}')  format('truetype')`,
  fontWeight: 'bold',
  fontStyle: 'normal'
}, {
  fontFamily: headings,
  src: oneLineTrim`
    url('${require('./GT-Cinetype-Bold.eot').default}?#iefix') format('embedded-opentype'),
    url('${require('./GT-Cinetype-Bold.woff2').default}') format('woff2'),
    url('${require('./GT-Cinetype-Bold.woff').default}') format('woff'),
    url('${require('./GT-Cinetype-Bold.ttf').default}')  format('truetype')`,
  fontWeight: headingsFontWeight,
  fontStyle: 'normal'
}]

export const preload = [
  require('./GT-America-Mono-Regular.woff2').default,
  require('./GT-America-Mono-Bold.woff2').default,
  require('./GT-Cinetype-Bold.woff2').default
].filter(item => !item.startsWith('data:'))
