import inDOM from 'dom-helpers/canUseDOM'

const supportsScrollBehavior = inDOM && 'scrollBehavior' in document.createElement('div').style

export default function instantScrollTo (top) {
  if (supportsScrollBehavior) {
    window.scrollTo({ top, behavior: 'instant' })
  } else {
    window.scrollTo(0, top)
  }
}
