import { isRouteActionType, toggleMenuCreator } from '../../actions'
import { isMenuOpen } from '../../selectors'

export default store => next => action => {
  const ret = next(action)
  if (isRouteActionType(action.type) && isMenuOpen(store.getState())) {
    store.dispatch(toggleMenuCreator())
  }
  return ret
}
