import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'

const Technology = ({ className, technology }) => {
  const classes = useStyles()
  return (
    <ul className={cn(className, classes.technologies)}>
      {technology.map((tech, i) => tech && (
        <React.Fragment key={i}>
          <li className={classes.technology}>
            {tech.shortTitle || tech.title}
          </li>
          {' ' /* So that the browser can break the line between <li>s. */}
        </React.Fragment>
      ))}
    </ul>
  )
}

const useStyles = createUseStyles({
  technologies: {
    display: 'block',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    fontSize: 'inherit',
    fontWeight: 'bold',
    lineHeight: 1.6
  },
  technology: {
    display: 'inline',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    '&::after': {
      content: '"\\2022"',
      color: 'inherit',
      fontWeight: 'bold',
      lineHeight: 1.4,
      margin: [0, '0.66em']
    },
    '&:last-child': {
      paddingRight: 0,
      '&::after': {
        content: 'none'
      }
    }
  }

}, { name: 'Technology' })

export default Technology
