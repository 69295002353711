import React from 'react'
import Button from '../Button'
import Link from '../Link'
import { useSelector } from 'react-redux'
import { getCvDownloadLink } from '../../selectors'

const Download = ({ className }) => {
  const link = useSelector(getCvDownloadLink)
  return (
    <Button className={className}>
      <Link link={link}>{link.text}</Link>
    </Button>
  )
}

export default Download
