import { MODAL_DISMISSED, SHOW_ARCHIVED_LINK_MODAL } from '../actions'

const initialState = {
  open: false
}

const modal = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ARCHIVED_LINK_MODAL:
      return {
        ...state,
        open: true,
        type: 'archived',
        properties: action.payload
      }

    case MODAL_DISMISSED:
      return {
        ...state,
        open: false,
        type: undefined,
        properties: undefined
      }

    default:
      return state
  }
}

export default modal
