import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import Definitions from '../Definitions'

const isDefinitions = (highlights) => !!get(highlights, [0, 'detail'])

const Highlights = ({ className, highlights }) => {
  const classes = useStyles()
  if (isDefinitions(highlights)) {
    return <Definitions className={className} definitions={highlights} />
  }
  if (highlights.length === 1) {
    return <p className={cn(className, classes.highlights)}>{highlights[0]}</p>
  }
  return (
    <ul className={cn(className, classes.highlights)}>
      {highlights.map((highlight, i) => (
        <li key={i}>{highlight}</li>
      ))}
    </ul>
  )
}

const useStyles = createUseStyles({
  highlights: {
    marginBottom: 50
  }
}, { name: 'Highlights' })

export default Highlights
