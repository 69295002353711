export const BREAKPOINT_CHANGED = 'BREAKPOINT_CHANGED'

export function breakpointChanged (breakpoint) {
  return {
    type: BREAKPOINT_CHANGED,
    payload: {
      breakpoint
    }
  }
}
