import React, { useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import each from 'lodash/each'
import qsa from 'dom-helpers/querySelectorAll'
import theme from '../../styles/theme'
import mapStringChildren from '../../helpers/mapStringChildren'
import { addBackgroundColorListener, removeBackgroundColorListener } from '../../middlewares/view/colors'
import composeRefs from '../../helpers/composeRefs'

const useBoxColorScheme = (boxClassNames) => {
  const ref = useRef()
  useEffect(() => {
    const boxClassName = boxClassNames.split(' ')[0]
    const boxes = qsa(ref.current, `.${boxClassName}`)
    const listener = backgroundColor => {
      // Even gsap.quickSetter() isn't as fast as style.setProperty().
      each(boxes, box => {
        box.style.setProperty('background-color', backgroundColor)
      })
    }
    addBackgroundColorListener(listener)
    return () => {
      removeBackgroundColorListener(listener)
    }
  }, [boxClassNames])
  return ref
}

const EtchedText = React.forwardRef(({ className, children, etchRef }, forwardedRef) => {
  const classes = useStyles()
  const ref = composeRefs(forwardedRef, useBoxColorScheme(classes.box))
  return (
    <div className={cn(className, classes.etchedText)} ref={ref}>
      <div className={classes.boxes} ref={etchRef}>
        {mapStringChildren(children, text => <span className={cn(classes.text, classes.box)}>{text}</span>)}
      </div>
      <div className={classes.original}>
        {mapStringChildren(children, text => <span className={classes.text}>{text}</span>)}
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  etchedText: {
    position: 'relative',
    display: 'block'
  },
  clone: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    color: 'transparent'
  },
  boxes: {
    extend: 'clone',
    opacity: 0.9
  },
  box: {
    padding: ['0.2em', '0.3em'],
    backgroundColor: theme.colors.primary
  },
  original: {
    position: 'relative'
  }
}, { name: 'EtchedText' })

export default EtchedText
