export function configureLazySizes () {
  window.lazySizesConfig = window.lazySizesConfig || {
    loadHidden: false
  }
  window.lazySizesConfig.init = false
}

export default function () {
  configureLazySizes()
  require('lazysizes/plugins/attrchange/ls.attrchange')
  const lazysizes = require('lazysizes')
  lazysizes.init()
}
