/* global ResizeObserver */
import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import get from 'lodash/get'
import ResponsiveImage from '../../ResponsiveImage'
import theme from '../../../styles/theme'
import ScrollHint from './ScrollHint'
import { useDispatch, useSelector } from 'react-redux'
import { isCurrentBreakpointAtLeast, isRouteExitTransitionInProgress, isWorkRoute } from '../../../selectors'
import { scrollHintRequiredChangedCreator } from '../../../actions'

const isLargeBreakpointSelector = state => isCurrentBreakpointAtLeast(state, 'xl')
const isRequiredOnPage = state => isWorkRoute(state) && isLargeBreakpointSelector(state) && !isRouteExitTransitionInProgress(state)

function useMonitorScrollHintRequired () {
  const maybeRequired = useSelector(isRequiredOnPage)
  const dispatch = useDispatch()
  const dispatchRequiredChanged = useCallback((required) => {
    dispatch(scrollHintRequiredChangedCreator(required))
  }, [])
  const observeRef = useRef()

  return useCallback(el => {
    if (el && maybeRequired) {
      const ro = new ResizeObserver((entries) => {
        const { width } = get(entries, [0, 'contentRect'], {})
        let required
        if (width < theme.containerMaxWidth) {
          required = false
        } else {
          // All we care about is whether the images are offscreen when scrolled to the top
          const { top } = el.getBoundingClientRect()
          const absoluteTop = top + window.pageYOffset
          required = absoluteTop > document.documentElement.clientHeight
        }
        dispatchRequiredChanged(required)
      })
      ro.observe(el)
      observeRef.current = ro
    } else if (observeRef.current) {
      observeRef.current.disconnect()
      observeRef.current = null
    }
    if (!maybeRequired) {
      dispatchRequiredChanged(false)
    }
  }, [maybeRequired])
}

const Images = ({ className, images = [] }) => {
  const classes = useStyles()
  const ref = useMonitorScrollHintRequired()
  if (!images.length) {
    return null
  }
  return (
    <div className={cn(className, classes.images)} ref={ref}>
      <ScrollHint />
      {images.map((image, i) => (
        <ResponsiveImage
          key={i}
          className={cn(classes.image, image.isOpaque && classes.opaqueImage)}
          {...image}
        />
      ))}
    </div>
  )
}

const useStyles = createUseStyles({
  images: {
    margin: [100, 0],
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: [150, -25],
      alignItems: 'flex-start',
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('lg')]: {
      margin: [150, -50]
    }
  },
  image: {
    position: 'relative',
    width: '100%',
    margin: [50, 'auto', 50, 0],
    [theme.breakpoints.up('md')]: {
      margin: 25,
      width: 'calc(50% - 50px)'
    },
    [theme.breakpoints.up('lg')]: {
      margin: 50,
      width: 'calc(50% - 100px)'
    }
  },
  opaqueImage: {
    border: '1px solid'
  }
}, { name: 'Images' })

export default Images
