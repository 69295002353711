import config from './config'
import content from './content'
import layout from './layout'
import view from './view'
import modal from './modal'
export default {
  config,
  content,
  layout,
  view,
  modal
}
