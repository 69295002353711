import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import padStart from 'lodash/padStart'
import theme from '../../../styles/theme'
import Button from '../../Button'
import Link from '../../Link'

const Header = ({ className, number, title, type, link }) => {
  const classes = useStyles()
  return (
    <header className={cn(className, classes.header)}>
      <div className={classes.left}>
        <h4 className={classes.number}>{padStart(number, 2, '0')}</h4>
        <h2 className={classes.title}>{title}</h2>
        <h3 className={classes.type}>{type}</h3>
      </div>
      <div className={classes.right}>
        {link && (
          <Button className={classes.viewButton}>
            <Link link={link}>View project</Link>
          </Button>
        )}
      </div>
    </header>
  )
}

const useStyles = createUseStyles({
  header: {
    marginBottom: 24,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      paddingBottom: 24,
      marginBottom: 28,
      position: 'relative',
      '&::after': {
        // bottom border extends to the right outside the bounds of the container
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: -36,
        borderBottom: '1px solid'
      }
    }
  },
  number: {
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    marginTop: '-2.7em',
    marginBottom: '1em',
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 34,
      borderTop: '1px solid',
      marginRight: 10,
      height: '0.4em'
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-1.2em',
      fontSize: 18,
      '&::before': {
        width: 54
      }
    },
    [theme.breakpoints.up('xxl')]: {
      marginTop: '-2.7em'
    }
  },
  title: {
    fontSize: 28,
    marginBottom: '0.4em',
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      marginBottom: '0.2em'
    }
  },
  type: {
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 25
    }
  },
  left: {
    marginRight: 'auto',
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  right: {
    display: 'none',
    flex: '0 0 auto',
    alignSelf: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
}, { name: 'WorkHeader' })

export default Header
