import React from 'react'
import cn from 'classnames'

const Items = ({ className, classes, children }) => (
  <ul className={cn(className, classes.items)}>
    {React.Children.map(children, (child, i) => (
      <li key={child.key} className={classes.item}>
        {React.cloneElement(child, { classes })}
        {i < React.Children.count(children) - 1 && <span className={classes.divider} />}
      </li>
    ))}
  </ul>
)

export default Items
