import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { anchor, clearFix } from '../../styles/theme'

const Section = ({ className, id, title, children }) => {
  const classes = useStyles()
  return (
    <section className={cn(className, classes.container)}>
      <h3 className={classes.anchor} id={id}>{title}</h3>
      {children}
    </section>
  )
}

const useStyles = createUseStyles({
  container: {
    extend: clearFix,
    marginBottom: 50,
    position: 'relative'
  },
  anchor
}, { name: 'Section' })

export default Section
