import React, { useCallback, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import { isPreHydrate, isScrollHintVisible } from '../../../selectors'
import theme from '../../../styles/theme'

function useScrollHintAnimation (visible) {
  const hideTweenRef = useRef()
  const hintTweenRef = useRef()
  const ref = useCallback(el => {
    if (el) {
      const characters = Array.from(el.childNodes)
      hideTweenRef.current = gsap.to(
        characters,
        {
          autoAlpha: 0,
          duration: 0.1,
          stagger: 0.08,
          paused: true
        }
      )
      hintTweenRef.current = gsap.timeline({
        repeat: -1,
        repeatDelay: 0.5,
        paused: true
      })
        .fromTo(
          characters,
          {
            autoAlpha: 0
          },
          {
            autoAlpha: 1,
            duration: 0.15,
            stagger: 0.2
          },
          0.5)
        .to(
          characters,
          {
            autoAlpha: 0,
            duration: 0.15,
            stagger: 0.2
          },
          '+=1')
    } else if (hintTweenRef.current) {
      hintTweenRef.current.kill()
      hintTweenRef.current = null
    }
  }, [])

  useEffect(() => {
    if (!hideTweenRef.current) {
      return
    }
    if (visible) {
      hideTweenRef.current.pause()
      hintTweenRef.current.play(0)
    } else {
      hintTweenRef.current.pause()
      hideTweenRef.current.invalidate().play(0)
    }
  }, [visible])

  return ref
}

const ScrollHint = ({ className }) => {
  const classes = useStyles()
  const preHydrate = useSelector(isPreHydrate)
  const visible = useSelector(isScrollHintVisible)
  const ref = useScrollHintAnimation(visible)
  if (preHydrate) {
    return null
  }
  return ReactDOM.createPortal((
    <div aria-hidden='true' className={cn(className, classes.hint)} ref={ref}>
      <span className={classes.dash}>-</span>
      <span className={classes.dash}>-</span>
      <span className={classes.arrowHead}>></span>
    </div>
  ), document.body)
}

const useStyles = createUseStyles({
  hint: {
    position: 'absolute',
    left: 'calc(50% - 1.5em)',
    top: 'calc(100vh - 20px - 2em)',
    width: '3em',
    height: '2em',
    zIndex: theme.zIndex.scrollHint,
    transform: 'rotate(90deg)',
    cursor: 'pointer',
    textAlign: 'center',
    lineHeight: '2em',
    verticalAlign: 'middle',
    userSelect: 'none'
  },
  character: {
    opacity: 0
  },
  dash: {
    extend: 'character',
    position: 'relative',
    display: 'inline-block',
    transform: 'translateY(-0.033em)'
  },
  arrowHead: {
    extend: 'character'
  }
}, { name: 'ScrollHint' })

export default ScrollHint
