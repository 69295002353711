import React, { useMemo } from 'react'
import map from 'lodash/map'
import last from 'lodash/last'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Link from '../../Link'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import round from '../../../helpers/round'
import { srOnly } from '../../../styles/theme'
import useMatchTextColorFilter from '../../hooks/useMatchTextColorFilter'

const AwardBadges = React.forwardRef(({ className, awards }, ref) => {
  const classes = useStyles()
  const logos = useMemo(() => map(awards, award => {
    const logo = get(award, ['award', 'logo'])
    const svg = last(get(logo, ['sizes']))
    return svg && {
      ...svg,
      style: {
        width: logo.aspect >= 1 ? '1em' : `${round(logo.aspect)}em`,
        height: logo.aspect <= 1 ? '1em' : `${round(1 / logo.aspect)}em`
      }
    }
  }), [awards])
  const itemsRef = useMatchTextColorFilter(classes.logo)
  return (
    <aside className={cn(className, classes.awardBadges)} ref={ref}>
      {!isEmpty(awards) && (
        <>
          <header>
            <h3 className={classes.title}>Awards</h3>
          </header>
          <ul className={classes.items} ref={itemsRef}>
            {map(awards, ({ title, url }, i) => {
              const logo = logos[i]
              if (!logo) {
                return null
              }
              return (
                <li key={i} className={classes.item}>
                  <Link className={classes.link} to={url} target='_blank' nonLinkTag='span'>
                    <img className={classes.logo} src={logo.url} alt={title} width={logo.width} height={logo.height} style={logo.style} />
                  </Link>
                </li>
              )
            })}
          </ul>
        </>
      )}
    </aside>
  )
})

const useStyles = createUseStyles({
  title: {
    ...srOnly
  },
  awardBadges: {
    display: 'block',
    margin: '-0.125em',
    width: '1.25em',
    padding: 0
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    listStyleType: 'none',
    margin: 0,
    padding: 0
  },
  item: {
    display: 'block',
    margin: 0,
    padding: 0
  },
  link: {
    display: 'flex',
    boxShadow: 'none',
    width: '1.25em',
    height: '1.25em',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    display: 'block',
    margin: 'auto'
  }
}, { name: 'WorkAwardBadges' })

export default AwardBadges
