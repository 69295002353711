export const PORTFOLIO_HERO_ITEM_CHANGED = 'PORTFOLIO_HERO_ITEM_CHANGED'
export const BEGIN_ROUTE_EXIT_TRANSITION = 'BEGIN_ROUTE_EXIT_TRANSITION'
export const ROUTE_EXIT_TRANSITION_ENDED = 'ROUTE_EXIT_TRANSITION_ENDED'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const TOGGLE_SCROLLED_DOWN = 'TOGGLE_SCROLLED_DOWN'
export const LOCATION_HASH_CHANGED = 'LOCATION_HASH_CHANGED'
export const SCROLL_HINT_REQUIRED_CHANGED = 'SCROLL_HINT_REQUIRED_CHANGED'
export const SCROLL_HINT_TOGGLE_SCROLLED_DOWN = 'SCROLL_HINT_TOGGLE_SCROLLED_DOWN'
export const TOGGLE_SCROLL_HINT_DELAY_ELAPSED = 'TOGGLE_SCROLL_HINT_DELAY_ELAPSED'
export const TOGGLE_SCROLL_HINT_SEEN = 'TOGGLE_SCROLL_HINT_SEEN'
export const PORTFOLIO_MOUNTED = 'PORTFOLIO_MOUNTED'
export const PORTFOLIO_UNMOUNTED = 'PORTFOLIO_UNMOUNTED'
export const WORK_MOUNTED = 'WORK_MOUNTED'
export const WORK_UNMOUNTED = 'WORK_UNMOUNTED'

export const portfolioHeroItemChangedCreator = (heroIndex) => ({
  type: PORTFOLIO_HERO_ITEM_CHANGED,
  payload: heroIndex
})

export const beginRouteExitTransitionCreator = () => ({
  type: BEGIN_ROUTE_EXIT_TRANSITION
})

export const routeExitTransitionEndedCreator = () => ({
  type: ROUTE_EXIT_TRANSITION_ENDED
})

export const toggleMenuCreator = () => ({
  type: TOGGLE_MENU
})

export const toggleScrolledDownCreator = () => ({
  type: TOGGLE_SCROLLED_DOWN
})

export const createLocationHashChangedAction = hash => ({
  type: LOCATION_HASH_CHANGED,
  payload: hash
})

export const scrollHintRequiredChangedCreator = required => ({
  type: SCROLL_HINT_REQUIRED_CHANGED,
  payload: required
})

export const toggleScrollHintToggleScrolledDownCreator = () => ({
  type: SCROLL_HINT_TOGGLE_SCROLLED_DOWN
})

export const toggleScrollHintDelayElapsedCreator = () => ({
  type: TOGGLE_SCROLL_HINT_DELAY_ELAPSED
})

export const toggleScrollHintSeenCreator = () => ({
  type: TOGGLE_SCROLL_HINT_SEEN
})

export const portfolioMountedCreator = payload => ({
  type: PORTFOLIO_MOUNTED,
  payload
})

export const portfolioUnmountedCreator = () => ({
  type: PORTFOLIO_UNMOUNTED
})

export const workMountedCreator = payload => ({
  type: WORK_MOUNTED,
  payload
})

export const workUnmountedCreator = () => ({
  type: WORK_UNMOUNTED
})
