import {
  BEGIN_ROUTE_EXIT_TRANSITION,
  LOCATION_HASH_CHANGED,
  PORTFOLIO_HERO_ITEM_CHANGED,
  ROUTE_EXIT_TRANSITION_ENDED,
  SCROLL_HINT_REQUIRED_CHANGED,
  SCROLL_HINT_TOGGLE_SCROLLED_DOWN,
  TOGGLE_MENU,
  TOGGLE_SCROLL_HINT_DELAY_ELAPSED,
  TOGGLE_SCROLL_HINT_SEEN,
  TOGGLE_SCROLLED_DOWN
} from '../actions'

const initialState = {
  portfolioHeroIndex: null,
  menuOpen: false,
  scrolledDown: false,
  locationHash: '',
  routeExiting: false,
  scrollHint: {
    required: false,
    scrolledDown: false,
    delayElapsed: false,
    seen: false
  }
}

const view = (state = initialState, action) => {
  switch (action.type) {
    case PORTFOLIO_HERO_ITEM_CHANGED:
      return {
        ...state,
        portfolioHeroIndex: action.payload
      }
    case TOGGLE_MENU:
      return {
        ...state,
        menuOpen: !state.menuOpen
      }
    case TOGGLE_SCROLLED_DOWN:
      return {
        ...state,
        scrolledDown: !state.scrolledDown
      }
    case SCROLL_HINT_TOGGLE_SCROLLED_DOWN:
      return {
        ...state,
        scrollHint: {
          ...state.scrollHint,
          scrolledDown: !state.scrollHint.scrolledDown
        }
      }
    case SCROLL_HINT_REQUIRED_CHANGED:
      return {
        ...state,
        scrollHint: {
          ...state.scrollHint,
          required: action.payload
        }
      }
    case TOGGLE_SCROLL_HINT_DELAY_ELAPSED:
      return {
        ...state,
        scrollHint: {
          ...state.scrollHint,
          delayElapsed: !state.scrollHint.delayElapsed
        }
      }
    case TOGGLE_SCROLL_HINT_SEEN:
      return {
        ...state,
        scrollHint: {
          ...state.scrollHint,
          seen: !state.scrollHint.seen
        }
      }
    case LOCATION_HASH_CHANGED:
      return {
        ...state,
        locationHash: action.payload
      }
    case BEGIN_ROUTE_EXIT_TRANSITION:
      return {
        ...state,
        routeExiting: true
      }
    case ROUTE_EXIT_TRANSITION_ENDED:
      return {
        ...state,
        routeExiting: false
      }
    default:
      return state
  }
}

export default view
