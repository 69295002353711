import inDOM from 'dom-helpers/canUseDOM'
import get from 'lodash/get'
import {
  isRouteActionType,
  PORTFOLIO_MOUNTED,
  PORTFOLIO_UNMOUNTED,
  WORK_MOUNTED,
  WORK_UNMOUNTED
} from '../../actions'
import { isPortfolioRoute, isWorkRoute } from '../../selectors'

export default store => {
  if (!inDOM) {
    return next => action => next(action)
  }

  let workEl = null
  let itemsContainerEl = null
  let needsReset = false

  const setup = () => {
    if (!workEl || !itemsContainerEl) {
      return
    }

    if (isWorkRoute(store.getState())) {
      needsReset = true
      workEl.style.height = 'auto'
      workEl.style.overflow = 'visible'
      // Remove all other items from the page flow
      itemsContainerEl.style.height = 0
      itemsContainerEl.style.overflow = 'hidden'
    } else if (needsReset) {
      workEl.style.height = null
      workEl.style.overflow = null
      // Restore other items into page flow
      itemsContainerEl.style.height = null
      itemsContainerEl.style.overflow = null
      itemsContainerEl.style.visibility = null
      needsReset = false
    }
  }

  return next => action => {
    switch (action.type) {
      case WORK_MOUNTED:
        workEl = action.payload
        setup()
        break
      case WORK_UNMOUNTED:
        workEl = null
        break
      case PORTFOLIO_MOUNTED:
        itemsContainerEl = get(action.payload, [0, 'containerEl', 'parentNode'])
        setup()
        break
      case PORTFOLIO_UNMOUNTED:
        itemsContainerEl = null
        break
      // no default
    }

    const ret = next(action)

    if (isRouteActionType(action.type)) {
      if (isPortfolioRoute(store.getState()) || isWorkRoute(store.getState())) {
        setup()
      }
    }

    return ret
  }
}
