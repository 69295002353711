import React from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../styles/theme'
import EtchedText from './EtchedText'

const HeroTitle = React.forwardRef(({
  className, title, type, index, alignment = 'left',
  etchRef, typeRef, typeEtchRef
}, ref) => {
  const classes = useStyles({ alignment })
  return (
    <div className={cn(className, classes.heroTitle)} ref={ref}>
      <EtchedText etchRef={etchRef}>
        <h3 className={classes.title}>{title}</h3>
      </EtchedText>
      <div className={classes.typeContainer} ref={typeRef}>
        <EtchedText etchRef={typeEtchRef}>
          <h4 className={classes.type}>{type}</h4>
        </EtchedText>
      </div>
    </div>
  )
})

const useStyles = createUseStyles({
  heroTitle: ({ alignment }) => ({
    fontSize: 12,
    lineHeight: 1.5,
    textAlign: alignment,
    pointerEvents: 'none',
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      lineHeight: 1.6
    }
  }),
  title: {
    marginBottom: '0.5em',
    fontSize: '1.5em',
    lineHeight: 1.32,
    color: 'inherit',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2em'
    }
  },
  typeContainer: {
    // Looks better if the title is centred and then the type is not.
    position: 'absolute',
    fontSize: '0.8em',
    marginBottom: 0,
    top: '100%',
    width: '100%',
    left: ({ alignment }) => alignment === 'left' ? 0 : null,
    right: ({ alignment }) => alignment === 'left' ? null : 0,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2em'
    }
  },
  type: {
    fontSize: '1em',
    color: 'inherit',
    marginBottom: 0
  }
}, { name: 'HeroTitle' })

export default HeroTitle
