let createOrUpdateGsDevTools

if (process.env.REACT_APP_GREENSOCK_DEVTOOLS_ENABLED === 'true') {
  let gsDevTools
  createOrUpdateGsDevTools = () => {
    if (gsDevTools) {
      gsDevTools.kill()
      // Note: unfortunately GSDevTools cannot pick up updated tweens after hot reloading, even if we call create()
      // again, because it utilises a once-only recording phase during startup to detect tweens (look for the closure
      // variables `_recording` and `_startupPhase` in the source code). So, we need to re-execute the GSDevTools
      // module.
      delete require.cache[require.resolve('gsap/GSDevTools')]
    }
    const { GSDevTools: { create } } = require('gsap/GSDevTools')
    gsDevTools = create()
  }
} else {
  createOrUpdateGsDevTools = () => {}
}

export default createOrUpdateGsDevTools
